import React from 'react'
import i18n from '../../../i18n'
import { MemberClubType } from './types'
const CampaignsTable = ({ campaigns }: { campaigns: Array<any> }) => {
    const nextStep = 1
    return (
        <div>
            <h4>Campaigns</h4>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Message</th>
                            <th>Created Date</th>
                            <th>Targets</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (campaigns != null && campaigns.length > 0) && campaigns.map((campaign: any, index: any) => {
                                return <tr key={index}>
                                    <td>{campaign.message}</td>
                                    <td>{campaign.created_at}</td>
                                    <td>{campaign.clubmembers_target?.length}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default CampaignsTable
