import React, { useState } from 'react'
import i18n from '../../../i18n'
import { MemberClubType } from './types'

const CampaignStepper = ({ setStep, progressValue, selectedMembers, smsCampaign }: { setStep: (index: number) => void, progressValue: number, selectedMembers: Array<Number | null>, smsCampaign: any }) => {
    const [enableLevel, setenableLevel] = useState(4);
    return (
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            marginBottom: '2rem',
            position: 'relative'
        }}>
            <progress style={{
                position: 'absolute',
                width: "95%",
                zIndex: 1,
                marginBottom: 20,
                marginLeft: 30,
                marginRight: 40,
            }} id="progress" value={progressValue} max={100} />
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: 'center',
                flexDirection: 'column',
                zIndex: 2,
            }}>
                <button onClick={() => setStep(0)} type="button" className="btn btn-primary btn-circle btn-xl">
                    1
                </button>
                <span style={{ boxSizing: 'border-box' }}>{i18n.t("select-members")}</span>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: 'center',
                flexDirection: 'column',
                zIndex: 2,
            }}>
                <button disabled={enableLevel < 1} onClick={() => setStep(1)} type="button" className="btn btn-primary btn-circle btn-xl">
                    2
                </button>
                <span style={{ alignSelf: 'center' }} className="">{i18n.t("sms-message")}</span>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: 'center',
                flexDirection: 'column',
                zIndex: 2,
            }}>
                <button disabled={enableLevel < 2} onClick={() => setStep(2)} type="button" className="btn btn-primary btn-circle btn-xl">
                    3
                </button>
                <span style={{ alignSelf: 'center' }} className="">{i18n.t("test-sms-message")}</span>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: 'center',
                flexDirection: 'column',
                zIndex: 2,
            }}>
                <button disabled={enableLevel < 3} onClick={() => setStep(3)} type="button" className="btn btn-primary btn-circle btn-xl">
                    4
                </button>
                <span style={{ alignSelf: 'center' }} className="">{i18n.t("start-campaign")}</span>
            </div>
            <style>
                {
                    `
                    progress[value]::-webkit-progress-bar {
                        background-color: #eee;
                        border-radius: 2px;
                        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
                    }
                    progress[value] {
                        background-color: #eee;
                        border-radius: 2px;
                        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
                    }
                    progress[value]::-webkit-progress-value {
                        background-image:
                             -webkit-linear-gradient(-45deg, 
                                                     transparent 33%, rgba(0, 0, 0, .1) 33%, 
                                                     rgba(0,0, 0, .1) 66%, transparent 66%),
                             -webkit-linear-gradient(top, 
                                                     rgba(255, 255, 255, .25), 
                                                     rgba(0, 0, 0, .25)),
                             -webkit-linear-gradient(left, #3da5f4, #405189);
                      
                          border-radius: 2px; 
                          background-size: 35px 20px, 100% 100%, 100% 100%;
                      }
                    .btn-circle.btn-xl {
                        width: 70px;
                        height: 70px;
                        padding: 10px 16px;
                        border-radius: 35px;
                        font-size: 24px;
                        line-height: 1.33;
                    }
                    
                    .btn-circle {
                        width: 30px;
                        height: 30px;
                        padding: 6px 0px;
                        border-radius: 15px;
                        text-align: center;
                        font-size: 12px;
                        line-height: 1.42857;
                    }                    
                    `
                }
            </style>
        </div>
    )
}

export default CampaignStepper
