import { useEffect, useState } from 'react'
import { api, StrapiUserCustomType } from '../../utils/api';
import dayjs from 'dayjs'
import DatePicker from 'react-date-picker';
import i18n from '../../i18n';
import { useSelector } from 'react-redux';
import { RootState } from '../../configureStore';
import { RestaurantType } from '../../redux/restaurantSlice';
import { TableExport } from 'tableexport';
import { useHistory } from 'react-router-dom';
import numeral from 'numeral'
import { groupBy } from 'lodash';
import { OrderPaymentObj } from '../Financial/orders-financial.types';
const loadScript = function (src: string, id: string) {
    var tag = window.document.createElement('script');
    tag.async = false;
    tag.src = src;
    tag.id = id
    let previousTag = window.document.getElementById(id)
    if (previousTag)
        window.document.getElementsByTagName('body')[0].removeChild(previousTag)
    window.document.getElementsByTagName('body')[0].appendChild(tag);
}

export type LastOrderType = {
    id: number;
    total: number;
    address: string;
    user_id: number;
    fullname: string;
}

type CustomerType = {
    anniversary: null
    avgorder: number;
    backtobusiness2: number;
    backtosystem2: number;
    backtosystem5: number;
    backtosystem9: number;
    tranzilacommission: number;
    contributions: string;
    birthday: Date;
    fullname: string;
    gender: null
    lastorder: LastOrderType
    name: string;
    opt_out?: boolean;
    orderscount: number;
    phone: string;
    restaurant_id: number;
    user_id: number;
    total: number;
    clientDeliveryFee: number;
    totalcoins: number;
    totalWithCreditPayment: number;
    totalWithMabaliCoinsPayment: number;
    totalWithBusinessCoinsPayment: number;
    expensesOnOrders: number;
    expensesOnDeliveries: number;
    currentMabaliCoins: number;
    currentBusinessCoins: number;
    moneySpentPurchasingCoins: number;
    paymentObj?: {
        [key: string]: OrderPaymentObj
    },
    fineTime: number;
}

type MembersClubType = {
    balance: number;
    created_at: Date;
    id: number;
    opt_out:
    boolean;
    restaurant: RestaurantType | null
    updated_at: Date;
    users_permissions_user: StrapiUserCustomType | number
}

type CoinPurchaseType = {
    balance_after: number;
    balance_before: number;
    change: number;
    created_at: Date;
    fromUser: StrapiUserCustomType | null;
    id: number;
    membersclub: MembersClubType | null;
    order: any | null
    published_at: Date;
    restaurant: RestaurantType | null;
    source: "coinpurchase" | 'restaurantpercentage';
    toUser: StrapiUserCustomType | null;
    transfer_pending_account: any | null;
    updated_at: Date;
}

const defaultStartDate = dayjs().add(-30, 'day')
const defaultEndDate = defaultStartDate.add(30, 'day')

const prepareCustomerReport = (data: CustomerType[], membersclubs: MembersClubType[], coinPurchases: CoinPurchaseType[], groupById: 'user_id' | 'restaurant_id') => {

    const groupedCustomersOrders = groupBy(data, (report) => report[groupById])

    return Object.keys(groupedCustomersOrders).reduce((report, key: string) => {
        const customerOrders = groupedCustomersOrders[key];
        const customerData = customerOrders[0]
        const lastOrder = customerOrders[customerOrders.length - 1];

        const mabaliCoins = membersclubs.filter((membersclub) => {
            return Object.keys(membersclub.restaurant ?? {}).length === 0 && (membersclub.users_permissions_user as StrapiUserCustomType).id === customerData.user_id
        }).reduce((sum, memberclub) => {
            return sum + memberclub.balance;
        }, 0)


        const businessCoins = membersclubs.filter((membersclub) => {
            return Object.keys(membersclub.restaurant ?? {}).length > 0 && (membersclub.users_permissions_user as StrapiUserCustomType).id === customerData.user_id
        }).reduce((sum, memberclub) => {
            // console.log('memberclub', Object.keys(memberclub.restaurant ?? {}))
            return sum + memberclub.balance;
        }, 0)

        const coinsPurchased = coinPurchases.filter((coinPurchase) => {
            return coinPurchase.source === 'coinpurchase' && coinPurchase.membersclub?.users_permissions_user === customerData.user_id
        }).reduce((sum, coinPurchase) => {
            return sum + coinPurchase.change;
        }, 0)

        const customerRow = customerOrders.reduce((groupedCustomer, customer: CustomerType) => {

            const paymentMethods = customer.paymentObj ? Object.values<OrderPaymentObj>(customer.paymentObj) : [];
            const creditCardPaid = paymentMethods.filter((payment) => ['card', 'money'].includes(payment.type)).reduce((amount, payment) => {
                return amount + payment.amount;
            }, 0)
            const mabaliCoinsPaid = paymentMethods
                .filter((payment) => ['coin'].includes(payment.type))
                .filter((payment) => payment.restaurant == null)
                .reduce((amount, payment) => {
                    return amount + payment.amount;
                }, 0)
            const businessCoinsPaid = paymentMethods
                .filter((payment) => ['coin'].includes(payment.type))
                .filter((payment) => payment.restaurant != null)
                .reduce((amount, payment) => {
                    return amount + payment.amount;
                }, 0)


            // const businessFine = customer.fineTime > 0 ? customer.fineTime : 0;


            groupedCustomer.orderscount++;
            groupedCustomer.total += customer.total;
            groupedCustomer.backtobusiness2 = customer.backtobusiness2;
            groupedCustomer.backtosystem2 = customer.backtosystem2;
            groupedCustomer.backtosystem5 = customer.backtosystem5;
            groupedCustomer.backtosystem9 = customer.backtosystem9;
            groupedCustomer.totalcoins = customer.totalcoins;
            groupedCustomer.tranzilacommission = customer.tranzilacommission;
            groupedCustomer.totalWithCreditPayment += creditCardPaid;
            groupedCustomer.totalWithMabaliCoinsPayment += mabaliCoinsPaid;
            groupedCustomer.totalWithBusinessCoinsPayment += businessCoinsPaid;
            groupedCustomer.expensesOnOrders += customer.total;
            groupedCustomer.expensesOnDeliveries += customer.clientDeliveryFee;


            if (groupedCustomer.contributions) {
                groupedCustomer.contributions += ', '
            }
            groupedCustomer.contributions += customer.contributions

            return groupedCustomer
        }, {
            anniversary: customerData.anniversary,
            avgorder: customerData.avgorder,
            birthday: customerData.birthday,
            fullname: customerData.fullname,
            name: customerData.name,
            opt_out: customerData.opt_out,
            gender: customerData.gender,
            restaurant_id: customerData.restaurant_id,
            user_id: customerData.user_id,
            phone: customerData.phone,
            lastorder: {
                ...lastOrder as unknown as LastOrderType
            },
            orderscount: 0,
            total: 0,
            backtobusiness2: 0,
            backtosystem2: 0,
            backtosystem5: 0,
            backtosystem9: 0,
            contributions: '',
            totalcoins: 0,
            tranzilacommission: 0,
            totalWithBusinessCoinsPayment: 0,
            totalWithCreditPayment: 0,
            totalWithMabaliCoinsPayment: 0,
            expensesOnOrders: 0,
            expensesOnDeliveries: 0,

            currentMabaliCoins: mabaliCoins,
            currentBusinessCoins: businessCoins,
            moneySpentPurchasingCoins: coinsPurchased,


        } as CustomerType)
        report.push(customerRow)
        return report;
    }, [] as CustomerType[])
}


const Customer = () => {
    const [TableHeight, setTableHeight] = useState<any>("50vh")
    const [SelectedBusiness, setSelectedBusiness] = useState<string | number>("")

    const [Report, setReport] = useState<CustomerType[]>([])
    const [DOMLoaded, setDOMLoaded] = useState(false)
    const [membersclubs, setMembersclubs] = useState<MembersClubType[]>([]);
    const [coinPurchases, setCoinPurchases] = useState<CoinPurchaseType[]>([]);

    const [GlobalConfig, setGlobalConfig] = useState<any>(null);

    const getGlobalConfig = () => {
        api.get("/global-configs")
            .then(({ data }) => {

                data.totalContributions = 0

                data.totalContributions += data.businessCommission
                data.totalContributions += data.mabaliCommission
                data.totalContributions += data.communityCommission
                setGlobalConfig(data)
            })
    }
    useEffect(() => {
        setDOMLoaded(true)
        getGlobalConfig()
    }, [])

    useEffect(() => {

        if (DOMLoaded) {
            //@ts-ignore
            // loadScript('/js/dashboard.js', "dashboard")
            loadScript('/js/jq.tablesort.js', "tablesort")
            loadScript('/js/tablesorter.js', "tablesorter")
        }
    }, [DOMLoaded])


    const auth = useSelector((state: RootState) => state.auth)

    const getUsersBalance = () => {
        const userIds = Report.map((customer) => {
            return `users_permissions_user_in=${customer.user_id}`
        }).join(`&`)
        api.get(`/membersclubs?${userIds}`)
            .then(({ data }: { data: MembersClubType[] }) => {

                console.log("got membersclub", data)
                setMembersclubs(data);
            })
            .catch((e) => {
                console.log("could not get membersclub", e)
            })

    }
    const getCoinPurchases = () => {
        const userIds = Report.map((customer) => {
            return `membersclub.users_permissions_user_in=${customer.user_id}`
        }).join(`&`)
        api.get(`/membersclub-balance-histories?${userIds}&source=coinpurchase`)
            .then(({ data }: { data: CoinPurchaseType[] }) => {

                console.log("got membersclub balance history", data)
                setCoinPurchases(data);
            })
            .catch((e) => {
                console.log("could not get membersclub", e)
            })

    }

    useEffect(() => {

        if (Report.length === 0) {
            return;
        }
        getUsersBalance();
        getCoinPurchases();

    }, [Report]);

    const getFinancialReport = () => {
        api.get(`/restaurants/customers?restaurant=${SelectedBusiness}&start=${startDate.format("YYYY-MM-DD")}&end=${endDate.format("YYYY-MM-DD")}`)
            .then(({ data }: { data: CustomerType[] }) => {

                setReport(data)
            })
            .catch((e) => {
                console.log("could not get financial", e)
            })
    }



    const [startDate, onChangeStartDate] = useState(dayjs().add(-30, 'day'));
    const [endDate, onChangeEndDate] = useState(startDate.add(30, 'day'));

    useEffect(() => {

        //@ts-ignore
        if (auth.user && auth.user.restaurants.length) {
            setSelectedBusiness(auth.user?.restaurants.map((resta: any) => resta.id).join(","))
        }
    }, [auth])


    useEffect(() => {
        if (SelectedBusiness)
            getFinancialReport()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SelectedBusiness, startDate])


    useEffect(() => {

        let cardTitle = document.getElementsByClassName("card-title")[0].clientHeight
        let cardDescription = document.getElementsByClassName("card-title")[0].clientHeight
        let cardBody = document.getElementsByClassName("card-body")[0].clientHeight

        setTableHeight((cardBody - cardTitle - cardDescription))

    }, [])

    let summary = {
        totalOrders: 0,
        totalSpent: 0,
        avgOrder: 0,
        totalCommission: 0,
        totalMabaliCommission: 0,
        totalCommunityCommission: 0,
        totalBusinessCommission: 0,
        totalTranzilaCommission: 0,
        totalCoins: 0
    }

    const [groupByProp, setGroupByProp] = useState<'user_id' | 'restaurant_id'>('user_id');

    const preparedReport = prepareCustomerReport(Report, membersclubs, coinPurchases, groupByProp)

    return (<div className="container-fluid page-body-wrapper">
        <div className="main-panel">
            <div className="" style={{

            }}>
                <div className="row">

                    <div className="col-12 grid-margin">
                        <div className="card" style={{

                        }}>
                            <div className="card-body" style={{

                            }}>
                                <h4 className="card-title">{i18n.t("customer-data")}</h4>
                                <div className="card-description">
                                    <div className="form-group">
                                        <label htmlFor="exampleSelectGender">{i18n.t("business")}</label>
                                        <select className="form-control" id="exampleSelectGender" onChange={(e) => {
                                            console.log("onSelected Business", e.target.value)
                                            if (e.target.value === "0") {
                                                let selectedIds = ""
                                                auth.user?.restaurants.forEach((restaurant: RestaurantType) => {
                                                    if (selectedIds) {
                                                        selectedIds += ","
                                                    }
                                                    selectedIds += restaurant.id
                                                })
                                                setSelectedBusiness(selectedIds)
                                            } else {
                                                auth.user?.restaurants.forEach((restaurant: RestaurantType) => {
                                                    //@ts-ignore
                                                    if (restaurant.id === e.target.value)
                                                        setSelectedBusiness(restaurant.id)
                                                })

                                            }

                                        }}>
                                            <option value="0">{i18n.t("all")}</option>
                                            {auth.user?.restaurants && auth.user?.restaurants.map((restaurant: RestaurantType) => {
                                                return <option key={restaurant.id} value={restaurant.id}>{restaurant.name}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: 'row'
                                    }}>

                                        <span style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }}>{i18n.t("start-date")}</span>
                                        <DatePicker
                                            onChange={(date: any) => {
                                                console.log("change startDate", date)
                                                onChangeStartDate(dayjs(date ? date : defaultStartDate))
                                            }
                                            }
                                            value={startDate.toDate()}
                                        />
                                        <span style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }}>{i18n.t("end-date")}</span>
                                        <DatePicker
                                            onChange={(date: any) => {
                                                console.log("onChange endDate", date)
                                                onChangeEndDate(dayjs(date ? date : defaultEndDate))
                                            }
                                            }
                                            value={endDate.toDate()}
                                        />

                                        <button className="btn btn-success" style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }} onClick={() => {

                                            /* Bootstrap classes used to style and position the export button, i.e. when (`bootstrap: true` & `exportButtons: true`) */
                                            TableExport.prototype.bootstrapConfig = ["btn", "btn-primary", "separate"];
                                            //@ts-ignore
                                            new TableExport(document.getElementsByTagName("table"), {
                                                bootstrap: true,
                                                RTL: true,
                                                filename: "customer",
                                                sheetname: "customer"
                                            });
                                        }} ><img src="https://unpkg.com/tableexport/dist/img/xlsx.svg" alt='Export icon' /> {i18n.t("export-xlsx")}</button>
                                    </div>
                                </div>
                                <div className="row" style={{
                                    height: TableHeight,
                                    overflowY: "scroll"
                                }}>
                                    <div className="table-sorter-wrapper col-lg-12 table-responsive" style={{
                                        overflowX: 'visible'
                                    }}>
                                        <table id="sortable-table-1" className="table table-striped" style={{

                                        }}>
                                            <thead>
                                                <tr>
                                                    <th className="sortStyle">{"id"}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("name")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("telephone")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("dob")}<i className="mdi mdi-chevron-down"></i></th>

                                                    <th className="sortStyle">{i18n.t("anniversary")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("gender")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("orders-count")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("sum-orders")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">TOTAL WITH CC<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">TOTAL WITH MABALI COINS<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">TOTAL WITH BUSINESSES COINS<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">EXPENESES ON ORDERS<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">EXPENESES ON DELIVERY FEE<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">Current Mabali Coins<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">Current Business Coins<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">Money Spent Purchasing Coins<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("avgorder")}<i className="mdi mdi-chevron-down"></i></th>

                                                    <th className="sortStyle">{i18n.t("backtosystem9").format(GlobalConfig?.totalContributions)}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("backtosystem5").format(GlobalConfig?.mabaliCommission)}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("backtosystem2").format(GlobalConfig?.communityCommission)}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("backtosystembusiness2").format(GlobalConfig?.businessCommission)}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("tranzila-commission").format(GlobalConfig?.tranzilaCommission)}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">Donations<i className="mdi mdi-chevron-down"></i></th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {preparedReport.map((data: CustomerType, index: number) => {


                                                    summary.totalOrders += data.orderscount;
                                                    summary.totalCommission += data.backtosystem9;
                                                    summary.totalMabaliCommission += data.backtosystem5;
                                                    summary.totalCommunityCommission += data.backtosystem2;
                                                    summary.totalBusinessCommission += data.backtobusiness2;
                                                    summary.totalTranzilaCommission += data.tranzilacommission;
                                                    summary.avgOrder += (data.avgorder);
                                                    summary.totalSpent += (data.total);
                                                    summary.totalCoins += (data.totalcoins);

                                                    return <tr key={index}>
                                                        <td>{data.user_id}</td>
                                                        <td>{data.fullname}</td>
                                                        <td>{data.phone}</td>
                                                        <td>{dayjs(data.birthday).format("DD/MM/YY")}</td>
                                                        <td>{data.anniversary ? dayjs(data.anniversary!).format("DD/MM/YY") : ""}</td>
                                                        <td>{i18n.t(data.gender)}</td>
                                                        <td>{data.orderscount}</td>
                                                        <td>₪{numeral(data.total).format("0,0.00")}</td>
                                                        {/* Total with CC */}
                                                        <td>₪{numeral(data.totalWithCreditPayment).format("0,0.00")}</td>
                                                        {/* Total with Mabali Coins */}
                                                        <td>₪{numeral(data.totalWithMabaliCoinsPayment).format("0,0.00")}</td>
                                                        {/* Total with Business Coins */}
                                                        <td>₪{numeral(data.totalWithBusinessCoinsPayment).format("0,0.00")}</td>
                                                        {/* Expenses on Orders */}
                                                        <td>₪{numeral(data.expensesOnOrders).format("0,0.00")}</td>
                                                        {/* Expenses on Delivery Fee */}
                                                        <td>₪{numeral(data.expensesOnDeliveries).format("0,0.00")}</td>
                                                        {/* Current Mabali Coins */}
                                                        <td>₪{numeral(data.currentMabaliCoins).format("0,0.00")}</td>
                                                        {/* Current Business Coins */}
                                                        <td>₪{numeral(data.currentBusinessCoins).format("0,0.00")}</td>
                                                        {/* Money Spent Purchasing Coins */}
                                                        <td>₪{numeral(data.moneySpentPurchasingCoins).format("0,0.00")}</td>

                                                        <td>₪{numeral(data.avgorder).format("0,0.00")}</td>
                                                        <td>₪{numeral(data.backtosystem9).format("0,0.00")}</td>
                                                        <td>₪{numeral(data.backtosystem5).format("0,0.00")}</td>
                                                        <td>₪{numeral(data.backtosystem2).format("0,0.00")}</td>
                                                        <td>₪{numeral(data.backtobusiness2).format("0,0.00")}</td>
                                                        <td>₪{numeral(data.tranzilacommission).format("0,0.00")}</td>
                                                        {/* Donations */}
                                                        <td>{Object.keys(data.contributions.split(',')
                                                            .reduce((uniqueContrib: { [key: string]: number }, contrib: string) => {
                                                                uniqueContrib[contrib] || (uniqueContrib[contrib] = 0);
                                                                uniqueContrib[contrib]++;
                                                                return uniqueContrib;
                                                            }, {})).join(', ')}</td>


                                                    </tr>
                                                })}

                                                {/* <tr style={{
                                                    fontWeight: 800
                                                }} >
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{summary.totalOrders}</td>

                                                    <td>₪{numeral(summary.totalSpent).format("0,0.00")}</td>
                                                    <td>{numeral(summary.totalCoins).format("0,0.00")}</td>
                                                    <td></td>
                                                    <td>₪{numeral(summary.avgOrder / Report.length).format("0,0.00")}</td>
                                                    <td>₪{numeral(summary.totalCommission).format("0,0.00")}</td>
                                                    <td>₪{numeral(summary.totalMabaliCommission).format("0,0.00")}</td>
                                                    <td>₪{numeral(summary.totalCommunityCommission).format("0,0.00")}</td>
                                                    <td>₪{numeral(summary.totalBusinessCommission).format("0,0.00")}</td>
                                                    <td>₪{numeral(summary.totalTranzilaCommission).format("0,0.00")}</td>
                                                    <td></td>
                                                    <td></td>


                                                </tr> */}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <style>
            {`
                .separate > button {
                    margin-left : 5px;
                    margin-right : 5px;
                }
            `}
        </style>
    </div>

    )
}

export default Customer
