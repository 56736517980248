import React, { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux';
import {
  useParams
} from "react-router-dom";
import { RootState } from '../../configureStore';
import { RestaurantType } from '../../redux/restaurantSlice';

import { api, StrapiUserCustomType } from '../../utils/api';
import { Color } from '../../utils/Colors';
import ChatBody from './ChatBody';
import ChatTopOrder from './ChatTopOrder';
import MessageInput from './MessageInput';


export type ChatMessage = {
  "_id": string;
  "text": string;
  "user": {
    "_id": number;
  },
  "image"?: string;
  "createdAt": Date
}

export type ChatType = {
  id: string;
  chat: ChatMessage[];
  order: OrderType;
  newMessageBy: string;
  client: StrapiUserCustomType;
  name: string;
  user: StrapiUserCustomType;
  completed: boolean;
  driverChat: boolean;
}

export type OrderStatus = 'pending' | 'approved' | 'making' | 'ready' | 'delivered' | 'pickedup' | 'rejected' | 'completed' | 'canceled'

export type OrderType = {
  address?: string | null;
  created_at: Date;
  curbside?: boolean;
  delivery?: boolean;
  id: number;
  cancel: boolean;
  status: OrderStatus;
  completed: boolean;
  bolepoDriver: boolean;
  membersclub_balance_history: any;
  name: string;
  orderDate: Date;
  paid: boolean;
  paidWithCoins: boolean;
  phone: string;
  published_at: Date;
  restaurant: RestaurantType;
  scheduled?: boolean;
  takeout?: boolean;
  total: number;
  housedistancetime: number;
  preparationTime: number;
  orderType: "delivery" | "takeout" | "scheduleddelivery" | "scheduledpickup",
  updated_at: Date;
  verificationCode?: string;
  deliveryAddress: any;
  deliveryAddressText?: string;
  chat_agent: ChatType,
  scheduledDate: Date;
  pendingTime: Date,
  deliveryTime: Date,
  deliveredTime: Date,
  driverAccepted: Date,
  approvedTime: Date,
  makingTime: Date,
  users_permissions_user: StrapiUserCustomType;
}

export function OrderChat() {
  const [messages, setMessages] = useState([]);
  const [CurrentChat, setCurrentChat] = useState<ChatType | null>(null);
  const auth = useSelector((state: RootState) => state.auth)

  let { id }: any = useParams();

  const getUserChat = () => {
    api.get(`/chat-agents/${id}?driverChat=false`)
      .then(({ data }) => {
        console.log("data", data)
        const currentChat = data
        setCurrentChat(currentChat);
        setMessages(currentChat.chat || [])

      })
      .catch((e) => {
        console.warn("could not get messages", e.response.data);
      })
  }
  useEffect(() => {
    getUserChat();
  }, [])


  return (
    <div className='container-fluid' style={{
      flex: 1,
      width: "100%",
      overflowY: 'scroll',
      //  height :"90vh",
      backgroundColor: Color.lightGray
    }}>
      <ChatTopOrder order={CurrentChat?.order} />
      <ChatBody messages={CurrentChat?.chat} clientId={CurrentChat?.client.id} />
      {/* <MessageInput order={CurrentChat?.order} onRefresh={getUserChat} /> */}
    </div>
  )
}

export default OrderChat