
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { Provider } from 'react-redux'
import store from './configureStore'

import PrivateRoutes from './containers/PrivateRoutes';
import { ChakraProvider } from '@chakra-ui/react'
import Main from './pages/Main'
import Login from './pages/Login';
import Financial from './pages/Financial';
import Customer from './pages/Customer';
import Orders from './pages/Orders';
import Order from './pages/Order';
import Reviews from './pages/Reviews';
import TopBar from './containers/TopBar'
import SmsCampaignPage from "./pages/sms-campaign";

import dayjs from 'dayjs'
import OrderChat from "./pages/OrderChat";
import DriverChat from "./pages/OrderChat/DriverChat";
import FinancialDetails from "./pages/Financial/FinancialDetails";
import Deliveries from "./pages/Deliveries";
import CoinUserTransactionDetails from "./pages/Main/DashboardCounter/CounterDetails/CoinUserTransactionDetails";
import CoinPurchasedOrdersDetails from "./pages/Main/DashboardCounter/CounterDetails/CoinPurchasedOrdersDetails";
import CoinOrderCashbackDetails from "./pages/Main/DashboardCounter/CounterDetails/CoinOrderCashbackDetails";
import CoinPurchaseDetails from "./pages/Main/DashboardCounter/CounterDetails/CoinPurchaseDetails";
import ContributionDetails from "./pages/Main/DashboardCounter/CounterDetails/ContributionDetails";
import AverageOrderDetails from "./pages/Main/DashboardCounter/CounterDetails/AverageOrderDetails";
import OrdersPaidWith from "./pages/Main/DashboardCounter/CounterDetails/OrdersPaidWith";

require('dayjs/locale/he')
dayjs.locale('he')

// @ts-ignore
// eslint-disable-next-line no-extend-native
String.prototype.format = function () {
  let a = this;
  for (let k in arguments) {
    a = a.replace("{" + k + "}", arguments[k])
  }
  return a
}



function App() {


  return (
    <Provider store={store}>
      <ChakraProvider>
        <Router>
          <div>

            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
              {/* <Route path="/about">
            <About />
          </Route>*/}
              <Route path="/login">
                <Login />
              </Route>
              <PrivateRoutes >
                <div className="container-scroller">
                  <TopBar />
                  <Route exact path="/customer" component={Customer} />
                  <Route exact path="/financial-details/:restaurantId" component={FinancialDetails} />
                  <Route exact path="/financial" component={Financial} />
                  <Route exact path="/sms-campaign" component={SmsCampaignPage} />
                  <Route exact path="/bolepo-reviews" component={Reviews} />
                  <Route exact path="/business-reviews">
                    <Reviews business />
                  </Route>
                  <Route exact path="/drivers-reviews">
                    <Reviews driver />
                  </Route>
                  <Route exact path="/chat/:id" component={OrderChat} />
                  <Route path="/deliveries" component={Deliveries} />
                  <Route path="/orders" component={Orders} />
                  <Route path="/order/:id" component={Order} />
                  <Route path="/coin-user-transactions" component={CoinUserTransactionDetails} />
                  <Route path="/coin-purchased-orders-details" component={CoinPurchasedOrdersDetails} />
                  <Route path="/coin-order-cashback-details" component={CoinOrderCashbackDetails} />
                  <Route path="/coin-purchases-details" component={CoinPurchaseDetails} />
                  <Route path="/contributions-details" component={ContributionDetails} />
                  <Route path="/average-orders-details" component={AverageOrderDetails} />
                  <Route path="/orders-paid-with-coins-details" component={OrdersPaidWith} />
                  <Route exact path="/driver/chat/:id" component={DriverChat} />
                  <Route exact path="/" component={Main} />
                </div>
              </PrivateRoutes>
            </Switch>
          </div>
        </Router>
      </ChakraProvider>
    </Provider>
  );
}

export default App;
