import dayjs from 'dayjs'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../configureStore'

import { RestaurantType } from '../../redux/restaurantSlice'
import { ChatMessage } from './OrderChat'

const USER_BUBBLE = "#0C68F7";
const YOU_BUBBLE = "#ECECEC";

const USER_TEXT = "#FFFFFF";
const YOU_TEXT = "#000000";

const itsMe = (message: ChatMessage, userId: number) => {
  return message.user._id !== userId
}

export const bubbleColor = (message: ChatMessage, userId: number) => {
  if (itsMe(message, userId)) {
    return USER_BUBBLE;
  } else {
    return YOU_BUBBLE;
  }
}

export const bubbleTextColor = (message: ChatMessage, userId: number) => {
  if (itsMe(message, userId)) {
    return USER_TEXT;
  } else {
    return YOU_TEXT;
  }
}

export const chatDirection = (message: ChatMessage, userId: number) => {
  if (!itsMe(message, userId)) {
    return 'flex-start';
  } else {
    return 'flex-end';
  }
}

const ChatBubble = ({ message, userId }: { message: ChatMessage, userId: number | undefined }) => {
  const restaurant: RestaurantType | undefined = useSelector((state: RootState) => state.restaurant)
  const color = bubbleColor(message, restaurant?.id || userId!);
  const textColor = bubbleTextColor(message, restaurant?.id || userId!);

  const renderContent = () => {
    if (message.text) {
      return <span style={{
        fontSize: 16,
        color: textColor
      }}>
        {message.text}
      </span>
    } else if (message.image) {
      return <img className='bubble-img' width={200} src={message.image} alt="message bubble"/>
    }
  }

  return (
    <div className='shadow-sm' style={{
      // padding: 10,
      paddingTop : 5,
      paddingLeft : 10,
      paddingRight : 10,
      backgroundColor: color,
      border: '1px solid lightgray',
      display: 'flex',
      width: 'auto',
      flexDirection: 'column',
      marginTop : 10,
      borderRadius : 10,
      minWidth: 80,
      alignSelf : chatDirection(message, restaurant?.id || userId!)
    }}>

      {renderContent()}
      <div style={{
        // height: 10,
        width : "100%",
        display : 'flex',
        justifyContent : 'flex-end'
      }}>
        <span style={{
          fontSize: 10,
          color: textColor
        }}>{dayjs(message.createdAt).format('HH:mm a')}</span>
      </div>
    </div>
  )
}

export default ChatBubble