import React from 'react'
import i18n from '../../../i18n'
import { MemberClubType } from './types'
const MembersTable = ({
    clubMembers, onCheckMember, selectedMembers, onCheckAllMembers, setStep }:
    { clubMembers: any | null, onCheckMember: (memberId: number) => void, selectedMembers: Array<Number>, onCheckAllMembers: () => void, setStep: (index: number) => void }) => {
    const nextStep = 1
    return (
        <div>
            <h4 style={{ fontWeight: 'bold' }} className="">{i18n.t("select-club-members")}</h4>
            <div className="d-flex justify-content-between align-content-center">
                <p>{i18n.t("selected-members-count").format(selectedMembers.length?.toString())}</p>
                <button onClick={() => setStep(nextStep)} style={{ alignSelf: 'flex-end' }} type="button" className="mt-4 btn btn-primary btn-rounded btn-fw">
                    {i18n.t("next")}
                </button>
            </div>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>
                                <div className="form-check form-check-primary">
                                    <label className="form-check-label">
                                        <input
                                            checked={clubMembers.length === selectedMembers.length}
                                            onClick={(e) => {
                                                onCheckAllMembers();
                                            }}
                                            onChange={() => { }}
                                            type="radio"
                                            className="form-check-input"
                                        />
                                        <i className="input-helper" />
                                    </label>
                                </div>
                            </th>
                            <th>#</th>
                            <th>{i18n.t("telephone")}</th>
                            <th>{i18n.t("username")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (clubMembers != null && clubMembers.length > 0) && clubMembers.map((member: any, index: any) => {
                                return <tr key={index}>
                                    <td>
                                        <div className="form-check form-check-primary">
                                            <label className="form-check-label">
                                                <input
                                                    checked={selectedMembers.includes(member.id)}
                                                    onClick={() => { onCheckMember(member.id) }}
                                                    onChange={() => { }}
                                                    type="radio"
                                                    className="form-check-input"
                                                />
                                                <i className="input-helper" />
                                            </label>
                                        </div></td>
                                    <td className="py-1">
                                        <img src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" alt="image" />
                                    </td>
                                    <td>{member.users_permissions_user.phone}</td>
                                    <td>{member.users_permissions_user.email}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="d-flex align-content-center justify-content-end">
                <button onClick={() => setStep(nextStep)} type="button" className="mt-4 btn btn-primary btn-rounded btn-fw">
                    {i18n.t("next")}
                </button>
            </div>
        </div>
    )
}

export default MembersTable
