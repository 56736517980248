import { useCallback, useEffect, useState } from 'react'
import { api } from '../../utils/api';
import dayjs from 'dayjs'
import DatePicker from 'react-date-picker';
import i18n from '../../i18n';
import { useSelector } from 'react-redux';
import { RootState } from '../../configureStore';
import { RestaurantType } from '../../redux/restaurantSlice';
import { TableExport } from 'tableexport';
import { useHistory } from 'react-router-dom';
import numeral from 'numeral'
import { ChatType } from '../OrderChat/OrderChat';
import { OrderDataType } from '../Financial/orders-financial.types';

const loadScript = function (src: string, id: string) {
  var tag = window.document.createElement('script');
  tag.async = false;
  tag.src = src;
  tag.id = id
  let previousTag = window.document.getElementById(id)
  if (previousTag)
    window.document.getElementsByTagName('body')[0].removeChild(previousTag)
  window.document.getElementsByTagName('body')[0].appendChild(tag);
}

export type OrderType = {
  backtobusiness2: number;
  backtosystem2: number;
  backtosystem5: number;
  backtosystem9: number;
  tranzilacommission: number;
  birthday: string | null;
  bolepoDriver: null
  bolepocoins: null
  address: string;
  businesscoins: null;
  pos: any;
  created_at: string | null;
  deliverytime: string | null;
  firstorderbolepo: number
  firstorderbusiness: number
  deliveryMinutes: number
  fullname: string;
  gender: null
  id: number;
  is_member: boolean;
  name: string;
  opt_out: null
  phone: string;
  restaurant_id: number;
  review: {
    bolepo_content: any;
    bolepo_review: any;
    business_content: any;
    business_review: any
  },
  total: number;
  user_id: number;
  locationFee: number;
  expressDeliveryFee: number;
  businessDeliveryFee: number;
  clientDeliveryFee: number;
  deliveryRewards: number;
  deliveryNoRewards: number;
  deliveryFined: number;
  rewards: number;
  smallrewards: number;
  none: number;
  fined: number;
  chat_agent?: ChatType,
  driver_chat_agent?: ChatType
}


type ReportDataType = {
  id: string;
  businessName: string;
  totalCustomers: number;
  totalOrders: number;
  totalDeliveries: number;
  totalTakeout: number;
  totalDeliveryFee: number;
  totalOrdersWithExtraFeeForDistance: number;
  totalExtraDistanceFees: number;
  totalOrdersWithoutFee: number;
  totalOrdersUnder25Minutes: number;
  incomeInNISUnder25: number;
  totalOrdersBetween2535Minutes: number;
  incomeInNISBetween2535: number;
  totalOrdersBetween3550Minutes: number;
  incomeInNISBetween3550: number;
  totalOrdersOver50Minutes: number;
  incomeInNISOver50: number;
  totalOrdersRefunded: number;
  refundValueInNIS: number;
  totalOrdersCompensated: number;
  compensationsValue: number;
}

const defaultStartDate = dayjs().add(-30, 'day')
const defaultEndDate = defaultStartDate.add(30, 'day')

const Customer = () => {
  const [allOrders, setAllOrders] = useState<OrderDataType[]>([]);
  const [TableHeight, setTableHeight] = useState<any>("50vh")
  const [SelectedBusiness, setSelectedBusiness] = useState<string | number>("")
  const [showSummary, setShowSummary] = useState<boolean>(true);

  const [Report, setReport] = useState<ReportDataType[]>([])
  const [DOMLoaded, setDOMLoaded] = useState(false)
  const [orderType, setOrderType] = useState("current")
  const [restaurantId, setRestaurantId] = useState<number>();

  const [GlobalConfig, setGlobalConfig] = useState<any>(null);

  const getGlobalConfig = () => {
    api.get("/global-configs")
      .then(({ data }) => {

        data.totalContributions = 0

        data.totalContributions += data.businessCommission
        data.totalContributions += data.mabaliCommission
        data.totalContributions += data.communityCommission
        setGlobalConfig(data)
      })
  }

  useEffect(() => {
    setDOMLoaded(true)
    getGlobalConfig()
  }, [])

  useEffect(() => {

    if (DOMLoaded) {
      //@ts-ignore
      loadScript('/js/jq.tablesort.js', "tablesort")
      loadScript('/js/tablesorter.js', "tablesorter")
    }
  }, [DOMLoaded])


  const auth = useSelector((state: RootState) => state.auth)

  const buildSummary = useCallback((data: OrderDataType[]) => {
    const groupedOrdersByRestaurant = data.reduce((group, order) => {
      const restaurantId = order.restaurant_id;
      group[restaurantId] = group[restaurantId] || [];
      group[restaurantId].push(order);
      return group
    }, {} as { [key: string]: OrderDataType[] })

    const groupedOrdersByCustomer = data.reduce((group, order) => {
      const userId = order.user_id;
      group[userId] = group[userId] || 0;
      group[userId]++;
      return group
    }, {} as { [key: string]: number })
    const summaryPerRestaurant: ReportDataType[] = []
    Object.keys(groupedOrdersByRestaurant).forEach((restaurantId) => {
      const orders = groupedOrdersByRestaurant[restaurantId];


      const orderUnder25Minutes = orders.filter((order) => dayjs(order.deliveredTime).diff(dayjs(order.approvedTime), 'minute') < 25);
      const orderBetween2535Minutes = orders.filter((order) => dayjs(order.deliveredTime).diff(dayjs(order.approvedTime), 'minute') >= 25 && dayjs(order.deliveredTime).diff(dayjs(order.approvedTime), 'minute') < 35);
      const orderBetween3550Minutes = orders.filter((order) => dayjs(order.deliveredTime).diff(dayjs(order.approvedTime), 'minute') >= 35 && dayjs(order.deliveredTime).diff(dayjs(order.approvedTime), 'minute') < 50);
      const orderOver50Minutes = orders.filter((order) => dayjs(order.deliveredTime).diff(dayjs(order.approvedTime), 'minute') >= 50);
      summaryPerRestaurant.push({
        id: restaurantId,
        businessName: orders[0].name,
        totalCustomers: Object.keys(groupedOrdersByCustomer).length,
        totalOrders: orders.length,
        totalDeliveries: orders.filter((order) => order.delivery).length,
        totalTakeout: orders.filter((order) => order.pickup).length,
        totalDeliveryFee: orders.reduce((total, order) => {
          return total + order.clientDeliveryFee;
        }, 0),
        totalOrdersWithExtraFeeForDistance: orders.filter((order) => order.clientDeliveryFee - order.basePriceClientDeliveryFee > 0).length,
        totalExtraDistanceFees: orders.reduce((total, order) => {
          return total + (order.clientDeliveryFee - order.basePriceClientDeliveryFee);
        }, 0),
        totalOrdersWithoutFee: orders.filter((order) => order.basePriceClientDeliveryFee === 0).length,
        totalOrdersUnder25Minutes: orderUnder25Minutes.length,
        incomeInNISUnder25: orderUnder25Minutes.reduce((total, order) => {
          return total + order.total;
        }, 0),
        totalOrdersBetween2535Minutes: orderBetween2535Minutes.length,
        incomeInNISBetween2535: orderBetween2535Minutes.reduce((total, order) => {
          return total + order.total;
        }
          , 0),
        totalOrdersBetween3550Minutes: orderBetween3550Minutes.length,
        incomeInNISBetween3550: orderBetween3550Minutes.reduce((total, order) => {
          return total + order.total;
        }, 0),
        totalOrdersOver50Minutes: orderOver50Minutes.length,
        incomeInNISOver50: orderOver50Minutes.reduce((total, order) => {
          return total + order.total;
        }, 0),
        totalOrdersRefunded: orders.filter((order) => order.refunded).length,
        refundValueInNIS: orders.filter((order) => order.refunded).reduce((total, order) => {
          return total + order.total;
        }, 0),
        totalOrdersCompensated: orders.filter((order) => order.compensated).length,
        compensationsValue: orders.filter((order) => order.compensated).reduce((total, order) => {
          return total + order.total;
        }, 0),

      } as ReportDataType)
    })

    setReport(summaryPerRestaurant)
  }, [])

  const buildDetailedReportForBusiness = useCallback(({ restaurantId, data }: { restaurantId: number; data: OrderDataType[] }) => {
    const summaryPerRestaurant: ReportDataType[] = []
    data.filter((order) => order.restaurant_id === restaurantId).forEach((order) => {


      const orderUnder25Minutes = dayjs(order.deliveredTime).diff(dayjs(order.approvedTime), 'minute') < 25;
      const orderBetween2535Minutes = dayjs(order.deliveredTime).diff(dayjs(order.approvedTime), 'minute') >= 25 && dayjs(order.deliveredTime).diff(dayjs(order.approvedTime), 'minute') < 35;
      const orderBetween3550Minutes = dayjs(order.deliveredTime).diff(dayjs(order.approvedTime), 'minute') >= 35 && dayjs(order.deliveredTime).diff(dayjs(order.approvedTime), 'minute') < 50;
      const orderOver50Minutes = dayjs(order.deliveredTime).diff(dayjs(order.approvedTime), 'minute') >= 50;
      summaryPerRestaurant.push({
        id: `${order.id}`,
        businessName: order.name,
        totalCustomers: 1,
        totalOrders: 1,
        totalDeliveries: order.delivery ? 1 : 0,
        totalTakeout: order.pickup ? 1 : 0,
        totalDeliveryFee: order.clientDeliveryFee,
        totalOrdersWithExtraFeeForDistance: (order.clientDeliveryFee - order.basePriceClientDeliveryFee) > 0 ? 1 : 0,
        totalExtraDistanceFees: order.clientDeliveryFee - order.basePriceClientDeliveryFee,
        totalOrdersWithoutFee: order.basePriceClientDeliveryFee === 0 ? 1 : 0,
        totalOrdersUnder25Minutes: orderUnder25Minutes ? 1 : 0,
        incomeInNISUnder25: orderUnder25Minutes ? order.total : 0,
        totalOrdersBetween2535Minutes: orderBetween2535Minutes ? 1 : 0,
        incomeInNISBetween2535: orderBetween2535Minutes ? order.total : 0,
        totalOrdersBetween3550Minutes: orderBetween3550Minutes ? 1 : 0,
        incomeInNISBetween3550: orderBetween3550Minutes ? order.total : 0,
        totalOrdersOver50Minutes: orderOver50Minutes ? 1 : 0,
        incomeInNISOver50: orderOver50Minutes ? order.total : 0,
        totalOrdersRefunded: order.refunded ? 1 : 0,
        refundValueInNIS: order.refunded ? order.total : 0,
        totalOrdersCompensated: order.compensated ? 1 : 0,
        compensationsValue: order.compensated ? order.total : 0,

      } as ReportDataType)
    })

    setReport(summaryPerRestaurant)
  }, [])

  const getFinancialReport = () => {
    api.get(`/restaurants/orders?restaurant=${SelectedBusiness}&start=${startDate.format("YYYY-MM-DD")}&end=${endDate.format("YYYY-MM-DD")}&type=${orderType}`)
      .then(({ data }: { data: OrderDataType[] }) => {
        console.log("got report", data)
        setAllOrders(data);

      })
      .catch((e) => {
        console.log("could not get financial", e)
      })
  }

  useEffect(() => {
    console.log('restaurantId', restaurantId);
    if (showSummary) {
      buildSummary(allOrders);
    }
    else if (restaurantId) {
      buildDetailedReportForBusiness({
        restaurantId, data: allOrders
      });
    }
    else {
      setReport([])
    }

  }, [allOrders, restaurantId, showSummary, buildDetailedReportForBusiness, buildSummary]);



  const [startDate, onChangeStartDate] = useState(dayjs().add(-30, 'day'));
  const [endDate, onChangeEndDate] = useState(startDate.add(30, 'day'));

  useEffect(() => {
    if (auth.user && auth.user.restaurants.length) {
      setSelectedBusiness(auth.user?.restaurants.map((resta: any) => resta.id).join(","))
    }
  }, [auth])


  useEffect(() => {
    // if(DOMLoaded)
    //     loadScript('/js/jq.tablesort.js', "tablesort")
    //     loadScript('/js/tablesorter.js', "tablesorter")
  }, [Report, DOMLoaded])

  useEffect(() => {
    if (SelectedBusiness)
      getFinancialReport()

  }, [SelectedBusiness, startDate, endDate, orderType])

  useEffect(() => {

    let cardTitle = document.getElementsByClassName("card-title")[0].clientHeight
    let cardDescription = document.getElementsByClassName("card-title")[0].clientHeight
    let cardBody = document.getElementsByClassName("card-body")[0].clientHeight
    console.log("cardBody", cardBody)
    setTableHeight((cardBody - cardTitle - cardDescription))

  }, [])

  const toggleSummary = ({ restaurantId }: { restaurantId: string }) => {
    if (!showSummary) {
      setRestaurantId(undefined)
    } else {
      setRestaurantId(parseInt(restaurantId))
    }
    setShowSummary(!showSummary);
  }


  return (<div className="container-fluid page-body-wrapper">
    <div className="main-panel">
      <div className="">
        <div className="row">

          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">{i18n.t("orders-data")}</h4>
                <div className="card-description">
                  <div className="form-group">
                    <label htmlFor="exampleSelectGender">{i18n.t("business")}</label>
                    <select className="form-control" id="exampleSelectGender" onChange={(e) => {
                      console.log("onSelected Business", e.target.value)
                      if (e.target.value == "0") {
                        let selectedIds = ""
                        auth.user?.restaurants.forEach((restaurant: RestaurantType) => {
                          if (selectedIds) {
                            selectedIds += ","
                          }
                          selectedIds += restaurant.id
                        })
                        setSelectedBusiness(selectedIds)
                      } else {
                        auth.user?.restaurants.forEach((restaurant: RestaurantType) => {
                          //@ts-ignore
                          if (restaurant.id == e.target.value)
                            setSelectedBusiness(restaurant.id)
                        })

                      }

                    }}>
                      <option value={0} >{i18n.t("all")}</option>
                      {auth.user?.restaurants && auth.user?.restaurants.map((restaurant: RestaurantType) => {
                        return <option key={restaurant.id} value={restaurant.id}>{restaurant.name}</option>
                      })}
                    </select>
                  </div>
                  <div style={{
                    display: "flex",
                    flexDirection: 'row'
                  }}>

                    <span style={{
                      marginLeft: 20,
                      marginRight: 20
                    }}>{i18n.t("start-date")}</span>
                    <DatePicker
                      onChange={(date: any) => {
                        console.log("change startDate", date)
                        onChangeStartDate(dayjs(date ? date : defaultStartDate))
                      }
                      }
                      value={startDate.toDate()}
                    />
                    <span style={{
                      marginLeft: 20,
                      marginRight: 20
                    }}>{i18n.t("end-date")}</span>
                    <DatePicker
                      onChange={(date: any) => {
                        console.log("onChange endDate", date)
                        onChangeEndDate(dayjs(date ? date : defaultEndDate))
                      }
                      }
                      value={endDate.toDate()}
                    />

                    <button className="btn btn-success" style={{
                      marginLeft: 20,
                      marginRight: 20
                    }} onClick={() => {

                      /* Bootstrap classes used to style and position the export button, i.e. when (`bootstrap: true` & `exportButtons: true`) */
                      TableExport.prototype.bootstrapConfig = ["btn", "btn-primary", "separate"];
                      //@ts-ignore
                      new TableExport(document.getElementsByTagName("table"), {
                        bootstrap: true,
                        RTL: true,
                        filename: "orders",
                        sheetname: "orders"
                      });
                    }} ><img src="https://unpkg.com/tableexport/dist/img/xlsx.svg" /> {i18n.t("export-xlsx")}</button>

                  </div>
                </div>
                <div className="row">
                  <div className="" style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: 40,
                    border: '1px solid #333',
                    borderRadius: 5

                  }}>
                    <button onClick={() => setOrderType("current")} className={"btn " + (orderType === "current" ? "btn-primary" : "btn-flat")}>{i18n.t("regular-order")}</button>
                    <button onClick={() => setOrderType("refunds")} className={"btn " + (orderType === "refunds" ? "btn-primary" : "btn-flat")}>{i18n.t("refunds-order")}</button>
                  </div>
                </div>
                <div className="row" style={{
                  height: TableHeight,
                  overflowY: "scroll"
                }}>
                  <div className="table-sorter-wrapper col-lg-12 table-responsive" style={{
                    overflowX: 'visible'
                  }}>
                    <table id="sortable-table-1" className="table table-striped">
                      <thead>
                        <tr>
                          <th className="sortStyle">{i18n.t("business-name")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-customers")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-orders")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-deliveries")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-takeout")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-delivery-fee")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-orders-with-extra-fee-for-distance")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-extra-distance-fees")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-orders-without-fee")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-orders-under-25-minutes")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("income-in-nis")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-orderes-between-2535-minutes")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("income-in-nis")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-orderes-between-3550-minutes")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("income-in-nis")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-orders-ver-50-minutes")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("income-in-nis")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-orders-refunded")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("refund-value-in-nis")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-orders-compensated")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("compensations-value")}<i className="mdi mdi-chevron-down"></i></th>

                        </tr>
                      </thead>
                      <tbody>
                        {Report.map((data: ReportDataType, index: number) => {

                          return <tr key={data.id} onClick={() => {
                            toggleSummary({ restaurantId: data.id })
                          }} style={{
                            cursor: 'pointer'
                          }}>
                            <td>{data.businessName}</td>
                            <td>{numeral(data.totalCustomers).format('0,0')}</td>
                            <td>{numeral(data.totalOrders).format('0,0')}</td>
                            <td>{numeral(data.totalDeliveries).format('0,0')}</td>
                            <td>{numeral(data.totalTakeout).format('0,0')}</td>
                            <td>{numeral(data.totalDeliveryFee).format('0,0')}</td>
                            <td>{numeral(data.totalOrdersWithExtraFeeForDistance).format('0,0')}</td>
                            <td>{numeral(data.totalExtraDistanceFees).format('0,0')}</td>
                            <td>{numeral(data.totalOrdersWithoutFee).format('0,0')}</td>
                            <td>{numeral(data.totalOrdersUnder25Minutes).format('0,0')}</td>
                            <td>{numeral(data.incomeInNISUnder25).format('$0,0')}</td>
                            <td>{numeral(data.totalOrdersBetween2535Minutes).format('0,0')}</td>
                            <td>{numeral(data.incomeInNISBetween2535).format('$0,0')}</td>
                            <td>{numeral(data.totalOrdersBetween3550Minutes).format('0,0')}</td>
                            <td>{numeral(data.incomeInNISBetween3550).format('$0,0')}</td>
                            <td>{numeral(data.totalOrdersOver50Minutes).format('0,0')}</td>
                            <td>{numeral(data.incomeInNISOver50).format('$0,0')}</td>
                            <td>{numeral(data.totalOrdersRefunded).format('0,0')}</td>
                            <td>{numeral(data.refundValueInNIS).format('$0,0')}</td>
                            <td>{numeral(data.totalOrdersCompensated).format('0,0')}</td>
                            <td>{numeral(data.compensationsValue).format('$0,0')}</td>

                          </tr>
                        })}




                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <style>
      {`
                .separate > button {
                    margin-left : 5px;
                    margin-right : 5px;
                }
            `}
    </style>
  </div>

  )
}

export default Customer
