//@ts-nocheck
import React, { useEffect, useState } from 'react'
import { api } from '../../utils/api';
import dayjs from 'dayjs'
import DatePicker from 'react-date-picker';
import i18n from '../../i18n';
import { useSelector } from 'react-redux';
import { RootState } from '../../configureStore';
import { RestaurantType } from '../../redux/restaurantSlice';
import { useHistory, useParams } from 'react-router-dom';
import Order from '.';
import numeral from 'numeral'

require('dayjs/locale/he')
dayjs.locale('he')

const loadScript = function (src: string, id: string) {
    var tag = window.document.createElement('script');
    tag.async = false;
    tag.src = src;
    tag.id = id
    let previousTag = window.document.getElementById(id)
    if (previousTag)
        window.document.getElementsByTagName('body')[0].removeChild(previousTag)
    window.document.getElementsByTagName('body')[0].appendChild(tag);
}

export type OrderDetailType = {
    "id": 198,
    "verificationCode": null,
    "restaurant": RestaurantType,
    "takeout": null,
    "scheduled": null,
    "delivery": true,
    "curbside": null,
    "orderDate": "2021-07-04T19:06:20.273Z",
    "published_at": "2021-07-04T19:06:21.349Z",
    "created_at": "2021-07-04T19:06:21.367Z",
    "updated_at": "2021-07-05T06:42:24.822Z",
    "paidWithCoins": false,
    "membersclub_balance_history": null,
    "paid": true,
    "name": "Pablo Zarate",
    "address": null,
    "phone": "+523123170749",
    "total": 21,
    "status": "delivered",
    "users_permissions_user": {
        "id": 14,
        "username": "pablozarateayala@gmail.com",
        "email": "pablozarateayala@gmail.com",
        "provider": "local",
        "confirmed": true,
        "blocked": false,
        "role": 1,
        "created_at": "2021-05-17T10:26:22.904Z",
        "updated_at": "2021-06-13T20:51:08.223Z",
        "phone": "+523123170749",
        "gender": "",
        "birthday": "2001-03-20",
        "firstName": "Pablo",
        "lastName": "Zarate",
        "businessManager": null,
        "driverManager": null,
        "avatar": null
    },
    "preparationTime": string | null,
    "readyTime": string | null,
    "review": {
        "id": 56,
        "evaluation": 5,
        "feedback": "A",
        "published_at": null,
        "created_at": "2021-07-04T19:23:54.671Z",
        "updated_at": "2021-07-05T06:46:04.795Z",
        "daft": null,
        "restaurant": 38,
        "order": 198,
        "system_evaluation": 5,
        "delivery_man_evaluation": 5,
        "delivery_man_feedback": "C",
        "user": 14,
        "draft": false,
        "system_feedback": "B",
        "isDelivery": null
    },
    "pickup": false,
    "dismissed": false,
    "oderType": null,
    "scheduledDate": null,
    "deliveryAddressText": "Colima ST null,null",
    "deliveryAddress": null,
    "coinsFunded": false,
    "housedistancetime": null,
    "deliveryTime": string | null,
    "bolepoDriver": false,
    "tip": null,
    "driverFee": null
}


const ItemRowV2 = ({ item, menuItem, Order }: { item: any, menuItem: any, Order: any | null }) => {

    // console.log("Order.cartObj ===>", Order.cartObj);
    // console.log("Order.restaurant ===>", Order?.restaurant.id);
    let Notes = []
    let Optional = []
    Order?.cartObj.restaurant[Order.restaurant.id].map((itemCustom: any, key) => {

        if (itemCustom.id != item.menu_item.id) {
            return null
        }

        itemCustom?.Customized?.MultiOptions.forEach((multi, index) => {

            Optional.push(
                <div key={index} className="d-flex justify-content-between" style={{ marginBottom: 0 }}>
                    <span>{multi.menu_optional["name_" + i18n.getLang()] || multi.menu_optional.name} - {multi["name_" + i18n.getLang()] || multi.name}</span>
                    <span>{Order?.restaurant.currency || "$"}{numeral(multi.priceChange).format("0,0.0")}</span>
                </div>
            )
        })

        if (itemCustom.Customized?.Notes)
            Notes?.push(<div key={Notes.length} className="d-flex justify-content-between" style={{ marginBottom: 0 }}>
                <span>{itemCustom.Customized?.Notes}</span>
            </div>)
    })
    return <div className="col mb-4">
        <div className="d-flex justify-content-between" style={{ marginBottom: 0 }}>
            <strong style={{ fontSize: 18 }}>{menuItem["name_" + i18n.getLang()] || menuItem.name}</strong>
            <strong style={{ fontSize: 18 }}><span>{Order?.restaurant.currency || "₪"}{numeral(menuItem.price).format("0,0.0")}</span></strong>
        </div>
        {item.menu_extras.length ? [<div key={1001} className="d-flex justify-content-between" style={{ marginBottom: 0 }}>
            <span>{i18n.t("extras-dots")}</span>
        </div>,
        item.menu_extras.map((extra, key) => {
            return <div key={key} className="d-flex justify-content-between" style={{ marginBottom: 0 }}>
                <span>{extra["name_" + i18n.getLang()] || extra.name}</span>
                <span>{Order?.restaurant.currency || "$"}{numeral(extra.priceChange).format("0,0.0")}</span>
            </div>
        }), Optional]
            : null}
        {/* Aditionals Data */}

        {/* OptionalTitle Data */}
        {/* OptionalSelection Data */}
        {item.menu_optional_items.length ? [<div key={1003} className="d-flex justify-content-between" style={{ marginBottom: 0 }}>
            <span>{i18n.t("optionals-title-dots")}</span>
        </div>,
        item.menu_optional_items.map((optional: any, key) => {
            return <div key={key} className="d-flex justify-content-between" style={{ marginBottom: 0 }}>
                <span>{optional.menu_optional["name_" + i18n.getLang()] || optional.menu_optional.name} - {optional["name_" + i18n.getLang()] || optional.name}</span>
                <span>{Order?.restaurant.currency || "$"}{numeral(optional.priceChange).format("0,0.0")}</span>
            </div>
        })]
            : null}



        {/* OptionalTitle Data */}
        {/* OptionalSelection Data */}

        {/* Notes Data */}
        {Notes.length > 0 ? <div>
            <div className="d-flex justify-content-between" style={{ marginBottom: 0 }}>
                <span>{i18n.t("notes-dots")}</span>

            </div>
            {Notes} </div> : null}
        {/* Notes Data */}
    </div>
}

const Customer = () => {
    const history = useHistory()
    const [SelectedBusiness, setSelectedBusiness] = useState<string>("")
    const params: any = useParams()
    const [Orderdetail, setOrderdetail] = useState<OrderDetailType | null>(null)
    const [Items, setItems] = useState([])
    const [DOMLoaded, setDOMLoaded] = useState(false)
    useEffect(() => {
        setDOMLoaded(true)

    }, [])

    useEffect(() => {

        if (DOMLoaded) {
            //@ts-ignore
            // loadScript('/js/dashboard.js', "dashboard")
            loadScript('/vendors/js/vendor.bundle.base.js', "bundle.base")
            loadScript('/js/jq.tablesort.js', "tablesort")
            loadScript('/js/tablesorter.js', "tablesorter")
        }
    }, [DOMLoaded])


    const auth = useSelector((state: RootState) => state.auth)

    useEffect(() => {

        getOrderDetails()
    }, [])

    const getOrderDetails = () => {
        api.get(`/orders/${params.id}`)
            .then(({ data }) => {
                console.log("order details", data)
                setOrderdetail(data)
                getOrderItems()
            })
            .catch((e) => {
                console.log("could not get order detail", e)
            })
    }
    const getOrderItems = () => {
        api.get(`/order-items?order=${params.id}`)
            .then(({ data }) => {
                console.log("order items details", data)
                setItems(data)
            })
            .catch((e) => {
                console.log("could not get order detail", e)
            })
    }

    const PrintElem = (e: any) => {
        e.preventDefault()


        window.print();
        //    mywindow.close();
        // }


        return true;

    }



    useEffect(() => {
        // if(DOMLoaded)
        //     loadScript('/js/jq.tablesort.js', "tablesort")
        //     loadScript('/js/tablesorter.js', "tablesorter")
    }, [DOMLoaded])

    if (!Orderdetail) {
        return <div className="container-fluid page-body-wrapper">
            <div className="square-box-loader">
                <div className="square-box-loader-container">
                    <div className="square-box-loader-corner-top"></div>
                    <div className="square-box-loader-corner-bottom"></div>
                </div>
                <div className="square-box-loader-square"></div>
            </div>
        </div>
    }



    return (<div className="container-fluid page-body-wrapper">
        <div className="main-panel">
            <div className="content-wrapper" id="order-main">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card px-2">
                            <div className="card-body">
                                <div className="container-fluid">
                                    <button onClick={() => history.goBack()} className="btn" style={{
                                        padding: 10,
                                        cursor: 'pointer',
                                        backgroundColor: 'transparent'
                                    }}>
                                        <img src={"/images/outline_arrow_back_black_24dp.png"} />
                                    </button>

                                    <h3 className="text-right my-5">#{Orderdetail.id}</h3>
                                    <hr />
                                </div>
                                <div className="container-fluid d-flex justify-content-between">
                                    <div className="col-lg-3 pl-0">
                                        <p className="mt-5 mb-2"><b>{Orderdetail.users_permissions_user.firstName + " " + Orderdetail.users_permissions_user.lastName}</b></p>
                                        <p>{Orderdetail.deliveryAddressText}</p>
                                    </div>
                                    <div className="col-lg-3 pr-0" >
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                            <img src={api.getMedia!(Orderdetail.restaurant.logo)} alt="business-logo" style={{ display: 'flex', alignSelf: 'flex-end', width: 100 }} />
                                        </div>
                                        <p className="mt-2 mb-2 text-right"><b>{
                                            //@ts-ignore
                                            Orderdetail.restaurant["name_" + i18n.getLang()] || Orderdetail.restaurant.name
                                        }</b></p>
                                        <p className="text-right">{Orderdetail.restaurant.address}</p>
                                        <p className="text-right">{Orderdetail.restaurant.telephone}</p>
                                    </div>
                                </div>
                                <div className="container-fluid d-flex justify-content-between">
                                    <div className="col-lg-3 pl-0">
                                        <p className="mb-0 mt-5">{i18n.t("date-time")} {dayjs(Orderdetail.created_at).format("DD/MM/YY HH:mm")}</p>
                                        <p>{i18n.t("ready-time")} {Orderdetail.readyTime ? dayjs(Orderdetail.readyTime).format("DD/MM/YY HH:mm") : null}</p>
                                        <p>{i18n.t("delivery-time")} {Orderdetail.deliveryTime ? dayjs(Orderdetail.deliveryTime).format("DD/MM/YY HH:mm") : null}</p>
                                    </div>
                                </div>
                                <div className="container-fluid mt-5 d-flex justify-content-center w-100">
                                    <div className="table-responsive w-100">
                                        <table className="table">
                                            <thead>
                                                <tr className="bg-dark text-white">
                                                    <th>#</th>
                                                    <th>{i18n.t("description")}</th>
                                                    <th className="text-right">{i18n.t("quantity")}</th>
                                                    <th className="text-right">{i18n.t("unitcost")}</th>
                                                    <th className="text-right">{i18n.t("total")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {Items.map((item: any, index) => {

                                                    const menuItem = item.menu_item
                                                    return (
                                                        <tr key={index} className="text-right">
                                                            {/* <td className="text-left">{index + 1}</td>
                                                            <td className="text-left">{menu_item["name_" + i18n.getLang()] || menu_item.name}</td>
                                                            <td>{item.quantity}</td>
                                                            <td>₪{item.price}</td>
                                                            <td>₪{(item.quantity) * (item.price)}</td> */}
                                                            <ItemRowV2 key={index}  {...{ item, menuItem, Order: Orderdetail }} />
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="container-fluid mt-5 w-100">
                                    <div className="d-flex justify-content-end" style={{ marginBottom: 0 }}>
                                        <span><strong style={{ fontSize: 18 }}>{i18n.t("total-items-dots")}</strong> {Items.length}</span>
                                    </div>
                                    <div className="d-flex justify-content-end" style={{ marginBottom: 0 }}>
                                        <span><strong style={{ fontSize: 18 }}>{i18n.t("total-price-dots")}</strong>{<span> {Orderdetail?.restaurant.currency || "$"}{numeral(Orderdetail?.total - Orderdetail?.shippingCost).format("0,0.0")}</span>}</span>
                                    </div>
                                    <div className="d-flex justify-content-end" style={{ marginBottom: 0 }}>
                                        <span><strong style={{ fontSize: 18 }}>{i18n.t("online-tip-dots")}</strong> {Orderdetail?.restaurant.currency || "$"}{"0"}</span>
                                    </div>
                                    <div className="d-flex justify-content-end" style={{ marginBottom: 0 }}>
                                        <span><strong style={{ fontSize: 18 }}>{i18n.t("shipping")} </strong> {Orderdetail?.restaurant.currency || "$"}{numeral(Orderdetail?.shippingCost).format("0,0.0")}</span>
                                    </div>
                                    <div className="d-flex justify-content-end" style={{ marginBottom: 0 }}>
                                        <span><strong style={{ fontSize: 18 }}>{i18n.t("total")} </strong> {Orderdetail?.restaurant.currency || "$"}{numeral(Orderdetail?.total).format("0,0.0")}</span>
                                    </div>
                                    <hr />
                                </div>
                                <div className="container-fluid w-100">
                                    <a href="" onClick={PrintElem} className="btn btn-primary float-right mt-4 ml-2"><i className="mdi mdi-printer mr-1"></i></a>
                                    {/* <a href="#" className="btn btn-success float-right mt-4"><i className="mdi mdi-telegram mr-1"></i></a> */}
                                </div>
                                <script>
                                    {`
                                    
                                    `}
                                </script>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer">
                <div className="w-100 clearfix">
                    <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © 2021 Mabali. All rights reserved.</span>
                    <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Powered by <i className="mdi mdi-heart-outline text-danger"> <a href="http://www.menual.net" target="_blank">MENUAL</a></i></span>
                </div>
            </footer>
        </div>
    </div>
    )
}

export default Customer
