import React, { useCallback, useEffect, useState } from 'react'
import { api } from '../../utils/api';
import dayjs from 'dayjs'
import DatePicker from 'react-date-picker';
import i18n from '../../i18n';
import { useSelector } from 'react-redux';
import { RootState } from '../../configureStore';
import { RestaurantType } from '../../redux/restaurantSlice';
import { TableExport } from 'tableexport';
import { useHistory } from 'react-router-dom';
import numeral from 'numeral'
import { OrderType } from '../Orders/Orders';
import { loadScript } from '../../utils/scripts';
import { FineStatus, OrderDataType } from '../Financial/orders-financial.types';

const defaultStartDate = dayjs().add(-30, 'day')
const defaultEndDate = defaultStartDate.add(30, 'day')

type DeliveryReport = {
  id: string;
  name: string;
  totalDeliveries: number;
  totalBasePriceDelivery: number;
  totalBonusesForDrivers: number;
  totalFinesForDrivers: number;
  totalIncome: number;
  totalDeliveriesWithExtraDistance: number;
  extraDistanceTotalIncome: number;
  totalDeliveriesWithoutExtra: number;
  deliveriesWithoutExtraDistanceTotalIncome: number;
  totalExpressDeliveries: number;
  totalIncomeExpressDeliveries: number;
  totalDeliveriesWithTips: number;
  totalCreditCardTipsCollected: number;
}

const Deliveries = () => {
  const history = useHistory()
  const [TableHeight, setTableHeight] = useState<any>("50vh")
  const [SelectedBusiness, setSelectedBusiness] = useState<string | number>("")
  const [showSummary, setShowSummary] = useState<boolean>(true);

  const [Report, setReport] = useState<DeliveryReport[]>([])
  const [DOMLoaded, setDOMLoaded] = useState(false)
  const [OrderType, setOrderType] = useState("current")

  const [startDate, onChangeStartDate] = useState(dayjs().add(-30, 'day'));
  const [endDate, onChangeEndDate] = useState(startDate.add(30, 'day'));



  const getGlobalConfig = () => {
    api.get("/global-configs")
      .then(({ data }) => {

        data.totalContributions = 0

        data.totalContributions += data.businessCommission
        data.totalContributions += data.mabaliCommission
        data.totalContributions += data.communityCommission
      })
  }

  useEffect(() => {
    setDOMLoaded(true)
    getGlobalConfig()
  }, [])

  useEffect(() => {

    if (DOMLoaded) {

      loadScript('/js/jq.tablesort.js', "tablesort")
      loadScript('/js/tablesorter.js', "tablesorter")
    }
  }, [DOMLoaded])


  const auth = useSelector((state: RootState) => state.auth)

  const getDeliveriesReport = useCallback(() => {
    api.get(`/restaurants/orders?restaurant=${SelectedBusiness}&start=${startDate.format("YYYY-MM-DD")}&end=${endDate.format("YYYY-MM-DD")}&type=${OrderType}`)
      .then(({ data }: { data: OrderDataType[] }) => {
        console.log("got report", data)

        const groupedOrdersByDriverId = data.filter((order => {
          return order.driverId
        })).reduce((group, order) => {
          const driverId = order.driverId!;
          group[driverId] = group[driverId] || [];
          group[driverId].push(order);
          return group
        }, {} as { [key: string]: OrderDataType[] })

        const driversReport: DeliveryReport[] = []

        Object.keys(groupedOrdersByDriverId).forEach((driverId) => {
          const orders = groupedOrdersByDriverId[driverId];
          const totalDeliveries = orders.length;
          const totalBasePriceDelivery = orders.reduce((total, order) => {
            return total + order.basePriceBusinessDeliveryFee + order.basePriceClientDeliveryFee;
          }, 0)
          const totalBonusesForDrivers = orders.filter(order => {
            return order.deliveryFineStatus !== FineStatus.fined
          }).reduce((total, order) => {
            return total + order.deliveryFineReward;
          }, 0)
          const totalFinesForDrivers = orders.filter(order => {
            return order.deliveryFineStatus === FineStatus.fined
          }).reduce((total, order) => {
            return total + order.deliveryFineReward;
          }, 0);

          const totalIncome = totalBasePriceDelivery + totalBonusesForDrivers - totalFinesForDrivers;

          const totalDeliveriesWithExtraDistance = orders.filter((order) => {
            return order.clientDeliveryFee > order.basePriceClientDeliveryFee
          })
          const extraDistanceTotalIncome = totalDeliveriesWithExtraDistance.reduce((total, order) => {
            return total + order.clientDeliveryFee - order.basePriceClientDeliveryFee;
          }, 0);
          const totalDeliveriesWithoutExtra = orders.filter((order) => {
            return order.clientDeliveryFee <= order.basePriceClientDeliveryFee
          }, 0);
          const deliveriesWithoutExtraDistanceTotalIncome = totalDeliveriesWithoutExtra.reduce((total, order) => {
            return total + order.clientDeliveryFee;
          }, 0);
          const totalExpressDeliveries = orders.filter((order) => {
            return order.expressOrder
          });
          const totalIncomeExpressDeliveries = totalExpressDeliveries.reduce((total, order) => {
            return total + order.expressDeliveryFee
          }, 0);
          const totalDeliveriesWithTips = orders.reduce((total, order) => {
            return total;
          }, 0);
          const totalCreditCardTipsCollected = orders.reduce((total, order) => {
            return total;
          }, 0);

          driversReport.push({
            id: driverId,
            name: orders[0].driverName || 'No name',
            totalDeliveries,
            totalBasePriceDelivery,
            totalBonusesForDrivers,
            totalFinesForDrivers,
            totalIncome,
            totalDeliveriesWithExtraDistance: totalDeliveriesWithExtraDistance.length,
            extraDistanceTotalIncome,
            totalDeliveriesWithoutExtra: totalDeliveriesWithoutExtra.length,
            deliveriesWithoutExtraDistanceTotalIncome,
            totalExpressDeliveries: totalExpressDeliveries.length,
            totalIncomeExpressDeliveries,
            totalDeliveriesWithTips,
            totalCreditCardTipsCollected,
          })
        })
        if (showSummary) {
          const driversReportSummary: DeliveryReport = {
            id: "summary",
            name: "summary",
            totalDeliveries: 0,
            totalBasePriceDelivery: 0,
            totalBonusesForDrivers: 0,
            totalFinesForDrivers: 0,
            totalIncome: 0,
            totalDeliveriesWithExtraDistance: 0,
            extraDistanceTotalIncome: 0,
            totalDeliveriesWithoutExtra: 0,
            deliveriesWithoutExtraDistanceTotalIncome: 0,
            totalExpressDeliveries: 0,
            totalIncomeExpressDeliveries: 0,
            totalDeliveriesWithTips: 0,
            totalCreditCardTipsCollected: 0,
          }
          driversReport.forEach((driverReport) => {
            driversReportSummary.totalDeliveries += driverReport.totalDeliveries
            driversReportSummary.totalBasePriceDelivery += driverReport.totalBasePriceDelivery
            driversReportSummary.totalBonusesForDrivers += driverReport.totalBonusesForDrivers
            driversReportSummary.totalFinesForDrivers += driverReport.totalFinesForDrivers
            driversReportSummary.totalIncome += driverReport.totalIncome
            driversReportSummary.totalDeliveriesWithExtraDistance += driverReport.totalDeliveriesWithExtraDistance
            driversReportSummary.extraDistanceTotalIncome += driverReport.extraDistanceTotalIncome
            driversReportSummary.totalDeliveriesWithoutExtra += driverReport.totalDeliveriesWithoutExtra
            driversReportSummary.deliveriesWithoutExtraDistanceTotalIncome += driverReport.deliveriesWithoutExtraDistanceTotalIncome
          });
          setReport([driversReportSummary])
        } else {
          setReport(driversReport)

        }
      })
      .catch((e) => {
        console.log("could not get financial", e)
      })
  }, [OrderType, SelectedBusiness, endDate, startDate, showSummary])




  useEffect(() => {
    if (auth.user?.restaurants.length) {
      setSelectedBusiness(auth.user.restaurants.map((resta: any) => resta.id).join(","))
    }
  }, [auth])

  useEffect(() => {
    if (SelectedBusiness)
      getDeliveriesReport()

  }, [SelectedBusiness, getDeliveriesReport])


  const toggleSummary = () => {
    setShowSummary(!showSummary)
  }

  return (<div className="container-fluid page-body-wrapper">
    <div className="main-panel">
      <div className="">
        <div className="row">

          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <div className="card-description">
                  <div className="form-group">
                    <label htmlFor="exampleSelectGender">{i18n.t("business")}</label>
                    <select className="form-control" id="exampleSelectGender" onChange={(e) => {
                      console.log("onSelected Business", e.target.value)
                      if (e.target.value == "0") {
                        let selectedIds = ""
                        auth.user?.restaurants.forEach((restaurant: RestaurantType) => {
                          if (selectedIds) {
                            selectedIds += ","
                          }
                          selectedIds += restaurant.id
                        })
                        setSelectedBusiness(selectedIds)
                      } else {
                        auth.user?.restaurants.forEach((restaurant: RestaurantType) => {
                          //@ts-ignore
                          if (restaurant.id === e.target.value)
                            setSelectedBusiness(restaurant.id)
                        })

                      }

                    }}>
                      <option value={0} >{i18n.t("all")}</option>
                      {auth.user?.restaurants && auth.user?.restaurants.map((restaurant: RestaurantType) => {
                        return <option key={restaurant.id} value={restaurant.id}>{restaurant.name}</option>
                      })}
                    </select>
                  </div>
                  <div style={{
                    display: "flex",
                    flexDirection: 'row'
                  }}>

                    <span style={{
                      marginLeft: 20,
                      marginRight: 20
                    }}>{i18n.t("start-date")}</span>
                    <DatePicker
                      onChange={(date: any) => {
                        console.log("change startDate", date)
                        onChangeStartDate(dayjs(date ? date : defaultStartDate))
                      }
                      }
                      value={startDate.toDate()}
                    />
                    <span style={{
                      marginLeft: 20,
                      marginRight: 20
                    }}>{i18n.t("end-date")}</span>
                    <DatePicker
                      onChange={(date: any) => {
                        console.log("onChange endDate", date)
                        onChangeEndDate(dayjs(date ? date : defaultEndDate))
                      }}
                      value={endDate.toDate()}
                    />

                    <button className="btn btn-success" style={{
                      marginLeft: 20,
                      marginRight: 20,
                      display: 'flex',
                      alignItems: 'center',
                    }} onClick={() => {

                      /* Bootstrap classes used to style and position the export button, i.e. when (`bootstrap: true` & `exportButtons: true`) */
                      TableExport.prototype.bootstrapConfig = ["btn", "btn-primary", "separate"];
                      //@ts-ignore
                      new TableExport(document.getElementsByTagName("table"), {
                        bootstrap: true,
                        RTL: true,
                        filename: "orders",
                        sheetname: "orders"
                      });
                    }} ><img src="https://unpkg.com/tableexport/dist/img/xlsx.svg" alt="export icon" /> {i18n.t("export-xlsx")}</button>

                  </div>
                </div>
                <div className="row">
                  <div className="" style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: 40,
                    border: '1px solid #333',
                    borderRadius: 5

                  }}>
                    <button onClick={() => setOrderType("current")} className={"btn " + (OrderType == "current" ? "btn-primary" : "btn-flat")}>{i18n.t("regular-order")}</button>
                    <button onClick={() => setOrderType("refunds")} className={"btn " + (OrderType == "refunds" ? "btn-primary" : "btn-flat")}>{i18n.t("refunds-order")}</button>
                  </div>
                </div>
                <div className="row" style={{
                  height: TableHeight,
                  overflowY: "scroll"
                }}>
                  <div className="table-sorter-wrapper col-lg-12 table-responsive" style={{
                    overflowX: 'visible'
                  }}>
                    <table id="sortable-table-1" className="table table-striped">
                      <thead>
                        <tr>
                          {!showSummary && <th> {i18n.t("name")}</th>}
                          <th className="sortStyle">{i18n.t("total-deliveries")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-base-price-delivery")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-bonuses-for-drivers")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-fines-for-drivers")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-income")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-deliveries-with-extra-distance")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("extra-distance-total-income")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-deliveries-without-extra")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("deliveries-without-extra-disntace-total-income")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-exress-deliveries")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-income-express-deliveries")}<i className="mdi mdi-chevron-down"></i></th>
                          {/* Not available for now leave null */}
                          <th className="sortStyle">{i18n.t("total-deliveries-with-tips")}<i className="mdi mdi-chevron-down"></i></th>
                          {/* Not available for now leave null */}
                          <th className="sortStyle">{i18n.t("total-credit-card-tips-collected")}<i className="mdi mdi-chevron-down"></i></th>



                        </tr>
                      </thead>
                      <tbody>
                        {Report.map((data: OrderType | any) => {


                          return <tr key={data.id} onClick={toggleSummary} style={{
                            cursor: 'pointer'
                          }}>
                            {!showSummary && <td>{data.name}</td>}
                            <td>{data.totalDeliveries}</td>
                            <td>₪{numeral(data.totalBasePriceDelivery).format('0,0')}</td>
                            <td>₪{numeral(data.totalBonusesForDrivers).format('0,0')}</td>
                            <td>₪{numeral(data.totalFinesForDrivers).format('0,0')}</td>
                            <td>₪{numeral(data.totalIncome).format('0,0')}</td>
                            <td>{data.totalDeliveriesWithExtraDistance}</td>
                            <td>₪{numeral(data.extraDistanceTotalIncome).format('0,0')}</td>
                            <td>{data.totalDeliveriesWithoutExtra}</td>
                            <td>₪{numeral(data.deliveriesWithoutExtraDistanceTotalIncome).format('0,0')}</td>
                            <td>{data.totalExpressDeliveries}</td>
                            <td>₪{numeral(data.totalIncomeExpressDeliveries).format('0,0')}</td>
                            <td>{data.totalDeliveriesWithTips}</td>
                            <td>₪{numeral(data.totalCreditCardTipsCollected).format('0,0')}</td>




                          </tr>
                        })}




                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <style>
      {`
                .separate > button {
                    margin-left : 5px;
                    margin-right : 5px;
                }
            `}
    </style>
  </div>

  )
}

export default Deliveries
