import React, { useEffect, useState } from 'react'
import i18n from '../../i18n'
import CampaignsList from './components/CampaignsList'
import CreateSmsCampaign from './components/CreateSMSCampaign'

const SmsCampaignPage = () => {
    const [tab, setTab] = useState('stats')
    const renderTab = () => {
        switch (tab) {
            case 'stats':
                return i18n.t("stats-tab")
            case 'history':
                return <CampaignsList />
            case 'create':
                return <CreateSmsCampaign></CreateSmsCampaign>
            default: return i18n.t("sms-campaign")
        }
    }
    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-2">
                                <div className="border-bottom text-center pb-4">
                                    <div className="mb-3">
                                        <h3>{i18n.t("sms-campaign")}</h3>
                                    </div>
                                </div>
                                <button onClick={() => {
                                    setTab('stats')
                                }} className="btn btn-primary btn-block mb-2">{i18n.t("stats-tab")}</button>
                                <button onClick={() => {
                                    setTab('history')
                                }} className="btn btn-primary btn-block mb-2">{i18n.t("all-campaigns-tab")}</button>
                                <button onClick={() => {
                                    setTab('create')
                                }} className="btn btn-primary btn-block mb-2">{i18n.t("create-campaign-tab")}</button>
                            </div>
                            <div className="col-lg-10" style={{
                                width: "100%",
                                minHeight: "75vh",
                                WebkitBoxShadow: "0px 0px 16px 4px rgba(0,0,0,0.09)",
                                boxShadow: "0px 0px 6px 2px rgba(0,0,0,0.09)",
                                borderRadius: 10,
                                padding: 25,
                            }}>
                                {renderTab()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SmsCampaignPage
