import React, { useEffect, useState } from 'react'
import i18n from '../../../i18n'
import { SMSCampaignType } from './types'

const MessageContentForm = ({ startSMSCampaign, testSMSCampaign, enableStartCampaign = false, stepIndex, setStep, selectedMembersIDs, createSMSCampaign, setMessageContent, messageContent, smsCampaign, creatingCampaign }:
    {
        startSMSCampaign: () => void,
        testSMSCampaign: () => void,
        enableStartCampaign: boolean, stepIndex: number, setStep: (index: number) => void, selectedMembersIDs: Array<Number>,
        createSMSCampaign: () => void, setMessageContent: (text: string) => void, messageContent: string, smsCampaign: SMSCampaignType | undefined, creatingCampaign: boolean
    }) => {
    let copiedTimer: any = null
    useEffect(() => {
        if (smsCampaign && smsCampaign.tested) {
            setStartWithoutTest(true)
        }
    }, [smsCampaign])
    const [showCopied, setShowCopied] = useState(false)
    const [startWithoutTest, setStartWithoutTest] = useState(false)
    const [businessBolepoURL, setBusinessBolepoURL] = useState("https://www.thisisthedeeplink.com")
    const copyBusinessLink = (link: string = "") => {
        navigator.clipboard.writeText(link)
        setShowCopied(true)
        if (copiedTimer) clearTimeout(copiedTimer);
        else {
            copiedTimer = setTimeout(() => setShowCopied(false), 1200)
        }
    }
    return (
        <div>
            {/* ...MESSAGE CONTENT... */}
            <div>
                <div className="text-center">
                    {enableStartCampaign ? <p className="fw-bold" style={{ fontSize: 18 }}>
                        {/*@ts-ignore*/}
                        {i18n.t("capaign-summary").format(selectedMembersIDs.length.toString(), "199.99", "BOLEPO")}
                    </p> : <h3>{i18n.t("sms-saidap-message").format("BOLEPO")}</h3>}

                    <h3 className="fw-bold">
                        <a className="link-dark" href={businessBolepoURL}>{businessBolepoURL}</a>
                        <button
                            style={{ fontWeight: 'bold' }}
                            type="button"
                            className="ml-2 btn btn-info btn-rounded btn-fw"
                            onClick={() => copyBusinessLink(businessBolepoURL)}
                        >
                            {i18n.t("copy-link")}
                        </button>
                        {showCopied && <span className="fw-bold text-success ms-2" style={{ fontSize: 14, position: 'absolute', marginTop: 10 }}>{i18n.t("copied")}</span>}
                    </h3>
                </div>
                <div className="mb-3">
                    <label htmlFor="message-content" className="form-label">
                        {i18n.t("message-to-send")}
                    </label>
                    <div className="d-flex">
                        <div className="col-12">
                            <textarea
                                disabled={enableStartCampaign}
                                value={messageContent}
                                onChange={(e) => {
                                    setMessageContent(e.target.value)
                                }}
                                maxLength={160}
                                required
                                placeholder={i18n.t("insert-content-here")}
                                className="form-control"
                                id="message-content"
                                rows={3}
                                defaultValue={""}
                                style={{ borderWidth: 2 }}
                            />
                        </div>
                    </div>
                    {!enableStartCampaign && <p style={{ position: 'absolute', marginTop: 0, marginLeft: 0 }} className="text-muted">{160 + "/" + messageContent.length}</p>}
                </div>
                {/*@ts-ignore*/}
                <p style={{ fontSize: 18 }} className="text-center fw-bold">{i18n.t("sms-target-amount").format(selectedMembersIDs.length.toString())}</p>
                {/* Bottom Buttons */}
                <div className='d-flex justify-content-center align-items-center flex-column mb-auto'>
                    {stepIndex == 1 && <button disabled={messageContent.length <= 0 || creatingCampaign} type="button" onClick={() => {
                        if (smsCampaign == undefined) {
                            createSMSCampaign()
                        }
                    }} className="mb-2 btn btn-primary btn-rounded btn-fw">{i18n.t("save")}</button>}
                    {stepIndex == 2 && <button onClick={() => {
                        testSMSCampaign()
                    }} type="button" className="mb-2 btn btn-primary btn-rounded btn-fw">{i18n.t("send-test-campaign")}</button>}
                    {stepIndex == 2 && <button type="button" onClick={() => { setStep(1) }} className="mb-2 btn btn-primary btn-rounded btn-fw">{i18n.t("back-edit-campaign")}</button>}
                    {
                        enableStartCampaign && <><button onClick={() => { startSMSCampaign(); setStep(3); }} disabled={!startWithoutTest} type="button" className="mb-2 btn btn-primary btn-rounded btn-fw">{i18n.t("send-campaign")}</button>
                            <label className="form-check-label">
                                <input
                                    checked={startWithoutTest}
                                    onClick={() => setStartWithoutTest(!startWithoutTest)}
                                    onChange={() => { }}
                                    type="checkbox"
                                    className="form-check-input" />
                                {i18n.t("start-campaign-no-test")}
                                <i className="input-helper" />
                            </label></>
                    }

                </div>
                {/* Bottom Buttons */}
            </div>
            {/* ...MESSAGE CONTENT... */}
        </div>
    )
}

export default MessageContentForm
