import { RestaurantType } from "../../redux/restaurantSlice";

export type OrderPaymentObj = {
    "id": number;
    "tip": number;
    "amount": number;
    "type": "coin" | 'card';
    "balance": number;
    "restaurant": string;

}

export enum OrderStatus {
    pending = 'pending',
    approved = 'approved',
    making = 'making',
    ready = 'ready',
    delivered = 'delivered',
    pickedup = 'pickedup',
    rejected = 'rejected',
    canceled = 'canceled'
}

export enum FineStatus {
    reward = 'reward',
    smallreward = 'smallreward',
    none = 'none',
    fined = 'fined',
}

export type OrderDataType = {
    allCommission: number;
    communityCommission: number;
    businessCommission: number;
    mabaliCommission: number;
    tranzilaCommission: number;
    business_coins_given: number;
    refunds: number;
    deliveryElapsed: number;
    driverFee: number;
    deliveryFineStatus: FineStatus;
    driverTip: number;
    birthday: Date;
    bolepocoins: null
    address: string;
    pos_type: string;
    shippingCost: number;
    fineTime: number;
    refunded: boolean;
    compensated: boolean;
    businesscoins: null;
    bolepoDriver: boolean;
    paid: boolean;
    paidWithCoins: boolean;
    deliveryTime: Date;
    status: OrderStatus;
    pickup: boolean;
    delivery: boolean;
    paymentObj?: {
        [key: string]: OrderPaymentObj
    };
    paymentObj2?: OrderPaymentObj;
    pos: any;
    orderType: string;
    created_at: string | null;
    deliverytime: string | null;
    firstorderbolepo: number;
    firstorderbusiness: number;
    fullname: string;
    orderDate: Date;
    mabaliDriver: boolean;
    clientDeliveryFee: number;
    businessDeliveryFee: number;
    expressDeliveryFee: number;
    deliveryMinutes?: number;
    gender: null
    id: number;
    is_member: string;
    name: string;
    opt_out: null;
    membersclub?: boolean;
    user_orders?: number;
    phone: string;
    restaurant_id: number;
    restaurant: RestaurantType;
    review: {
        bolepo_content: any;
        bolepo_review: any;
        business_content: any;
        business_review: any
    },
    total: number;
    user_id: number;
    businessDeliveryFine: number;
    deliveryFineReward: number;
    orderHasCashback: number;
    basePriceBusinessDeliveryFee: number;
    basePriceClientDeliveryFee: number;
    basePriceExpressDeliveryFee: number;
    approvedTime: Date | undefined;
    deliveredTime: Date | undefined;
    driverId: number | undefined;
    driverName: string | undefined;
    expressOrder: boolean;
}
