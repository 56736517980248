import React, { useEffect, useState } from 'react'
import { api } from '../../../utils/api'
import CampaignsTable from './CampaignsTable'

const CampaignsList = () => {
    const [campaigns, setCampaigns] = useState([])
    const fetchCampaigns = () => {
        api.get("sms-campaigns").then(({ data }) => setCampaigns(data)).catch(error => console.log(error))
    }
    useEffect(() => { fetchCampaigns() }, [])
    return (
        <div>
            <CampaignsTable campaigns={campaigns} />
        </div>
    )
}

export default CampaignsList
