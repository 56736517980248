import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    conversion : 0,
    bolepoComission : 0,
    businessComission : 0
}

const globalSlice = createSlice({
    name: "globalSlice",
    initialState,
    reducers: {
        setGlobalConfig: (state, action) => {
            state = action.payload
            return state
        }
    }
});

const traverseObject = (node :any, obj:any) => {
    console.log("traverseObject")
    Object.keys(obj).forEach((key) => {
        let filter = obj[key]
        console.log("traverseObject", key, filter)
        node[key] = typeof filter == 'object' ? traverseObject(filter, filter) : filter
    })
    return node
}

export const {
    setGlobalConfig
} = globalSlice.actions

export default globalSlice