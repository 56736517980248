import { Spinner } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from '../../configureStore'
import i18n from '../../i18n'
import { login } from '../../redux/authSlice'



const Login = () => {
    const dispatch = useDispatch()
    const auth = useSelector((state:RootState) => state.auth)
    const history = useHistory()

    const [Email, setEmail] = useState("")
    const [Password, setPassword] = useState("")
    // const [loading, setLoading] = useState<boolean>(false);

    const doLogin = (e:any) => {
        e.preventDefault()

        dispatch(login({ identifier: Email, password: Password }))
    }

    useEffect(() => {
        
        if(auth.jwt){
            history.push("/")
        }
    }, [auth, history])
    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="main-panel">
                    <div className="content-wrapper d-flex align-items-center auth px-0">
                        <div className="row w-100 mx-0">
                            <div className="col-lg-4 mx-auto">
                                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                    <div className="brand-logo" style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <img src="/images/appstore.png" style={{
                                            // height: 40,
                                            borderRadius: 10
                                        }} alt="logo" />
                                    </div>
                                    <h4>{"ניהול מערכת"}</h4>
                                    <h6 className="font-weight-light">{i18n.t("sign-in-to-continue")}</h6>
                                    <form className="pt-3">
                                        <div className="form-group">
                                            <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control form-control-lg" id="exampleInputEmail1" placeholder={i18n.t("username")} />
                                        </div>
                                        <div className="form-group">
                                            <input type="password"  onChange={(e) => setPassword(e.target.value)} className="form-control form-control-lg" id="exampleInputPassword1" placeholder={i18n.t("password")} />
                                        </div>
                                        <div className="mt-3">
                                            <button onClick={doLogin} className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" >
                                                
                                                {auth.authenticating ? <Spinner /> : i18n.t("sign-in")}
                                                </button>
                                        </div>
                                       

                                        {auth.loginError && <div className="alert alert-danger">{
                                            auth.loginError
                                        }</div>}

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
