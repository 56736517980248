import {configureStore } from '@reduxjs/toolkit'
import authSlice from './redux/authSlice'
import globalSlice from './redux/globalSlice'
import restaurantSlice from './redux/restaurantSlice'
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  restaurant: restaurantSlice.reducer,
  global : globalSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer
})

export default store