import { useCallback, useEffect, useState } from 'react'
import { api } from '../../utils/api';
import dayjs from 'dayjs'
import DatePicker from 'react-date-picker';
import i18n from '../../i18n';
import { useSelector } from 'react-redux';
import { RootState } from '../../configureStore';
import numeral from 'numeral'
import { TableExport } from 'tableexport';
import { useHistory } from 'react-router-dom';
import { FineStatus, OrderDataType, OrderStatus } from './orders-financial.types';
import { groupBy } from 'lodash';
const loadScript = function (src: string, id: string) {
  var tag = window.document.createElement('script');
  tag.async = false;
  tag.src = src;
  tag.id = id
  let previousTag = window.document.getElementById(id)
  if (previousTag)
    window.document.getElementsByTagName('body')[0].removeChild(previousTag)
  window.document.getElementsByTagName('body')[0].appendChild(tag);
}

export type ReportDataType = {
  id: string;
  address: string;
  location_city: string;
  tranzilacommission: number;
  location: number;
  businessCommission: number;
  tabit: number;
  bolepodriver: string;
  businessdriver: string;
  coinsnis: number;
  deliveryavg: number;
  driverfee: number;
  drivertip: number;
  givencoins: number;
  name: string;
  newmembersclub: string;
  orderavg: number;
  orderscount: number;
  deliveriescount: number;
  orderssum: number;
  reviews: number;
  smscost: number;
  smscount: number;
  telephone: string;
  totalcoins: number;
  locationFee: number;
  expressDeliveryFee: number;
  businessDeliveryFee: number;

  clientDeliveryFee: number;
  business_coins_given: number;
  takeout: number;
  deliveryIncome: number;
  takeoutIncome: number;
  totalMabaliDriverOrders: number;
  totalMabaliDriverOrdersMoney: number;
  totalMabaliDriverOrdersFined: number;
  totalMabaliDriverOrdersFinedMoney: number;
  totalOrdersWithCashback: number;
  totalOrdersWithCashbackMoney: number;
  totalMabaliFeeMoney: number;
  totalOrdersUnder80: number;
  totalOrdersUnder80Money: number;
  totalOrdersUnder120: number;
  totalOrdersUnder120Money: number;
  totalOrdersOver120: number;
  totalOrdersOver120Money: number;
  totalBusinessDeliveryFine: number;
  businessRevenue: number;
}

const defaultStartDate = dayjs().add(-30, 'day')
const defaultEndDate = defaultStartDate.add(30, 'day')

const Financial = () => {
  const [TableHeight, setTableHeight] = useState<any>("50vh")
  const [SelectedBusiness, setSelectedBusiness] = useState<string>("")
  const history = useHistory();

  const [Report, setReport] = useState<OrderDataType[]>([])
  const [DOMLoaded, setDOMLoaded] = useState(false)
  const [OrderType, setOrderType] = useState("current")
  const [reportPerBusiness, setReportPerBusiness] = useState<ReportDataType[]>([]);

  const [GlobalConfig, setGlobalConfig] = useState<any>(null);
  const [showSummary, setShowSummary] = useState<boolean>(true);
  const [restaurantId, setRestaurantId] = useState<number>();

  const showRestaurantDetails = (restaurantId: number) => {
    if (!showSummary) {
      setRestaurantId(undefined)
    } else {
      setRestaurantId(restaurantId);
    }
    setShowSummary(!showSummary)
  }

  const getGlobalConfig = () => {
    api.get("/global-configs")
      .then(({ data }) => {

        data.totalContributions = 0

        data.totalContributions += data.businessCommission
        data.totalContributions += data.mabaliCommission
        data.totalContributions += data.communityCommission
        setGlobalConfig(data)
      })
  }

  useEffect(() => {
    setDOMLoaded(true)
    getGlobalConfig();
  }, [])

  useEffect(() => {

    if (DOMLoaded) {
      //@ts-ignore
      // loadScript('/js/dashboard.js', "dashboard")
      loadScript('/js/jq.tablesort.js', "tablesort")
      loadScript('/js/tablesorter.js', "tablesorter")
    }
  }, [DOMLoaded])


  const auth = useSelector((state: RootState) => state.auth)

  const getFinancialReport = () => {
    api.get(`/restaurants/orders?start=${startDate.format("YYYY-MM-DD")}&end=${endDate.format("YYYY-MM-DD")}&type=${OrderType}`)
      .then(({ data }) => {
        console.log("got report", data)
        setReport(data)
      })
      .catch((e) => {
        console.log("could not get financial", e)
      })
  }



  const [startDate, onChangeStartDate] = useState(dayjs().add(-30, 'day'));
  const [endDate, onChangeEndDate] = useState(startDate.add(30, 'day'));

  useEffect(() => {
    if (auth.user && auth.user.restaurants.length)

      setSelectedBusiness(auth.user?.restaurants.map((resta) => resta.id).join(","))
  }, [auth])



  useEffect(() => {
    if (SelectedBusiness)
      getFinancialReport()

  }, [SelectedBusiness, startDate, endDate, OrderType])


  let TotalOrders = 0



  const reduceFinancialReport = (report: ReportDataType, data: OrderDataType) => {


    report.location = 0;

    report.coinsnis += data.total;
    report.deliveryavg += data.deliveryElapsed;
    report.driverfee += data.driverFee;
    report.drivertip += data.driverTip;
    report.givencoins += data.business_coins_given;
    report.orderavg += 0;

    report.orderssum += data.total + data.shippingCost;
    report.reviews += 0;
    report.smscost += 0;
    report.smscount += 0;
    report.totalcoins += (data.bolepocoins ?? 0) + (data.businesscoins ?? 0);
    report.locationFee += 0;
    report.expressDeliveryFee += data.expressDeliveryFee;
    report.businessDeliveryFee += data.businessDeliveryFee;
    report.clientDeliveryFee += data.clientDeliveryFee;


    report.takeout += data.pickup ? 1 : 0;
    report.takeout += data.pickup ? 1 : 0;

    report.deliveryIncome += data.delivery ? data.total : 0;
    report.takeoutIncome += data.pickup ? data.total : 0;



    if (data.mabaliDriver) {

      report.totalMabaliDriverOrders += 1;
      report.totalMabaliDriverOrdersMoney += data.clientDeliveryFee;

      const finedDriver = data.deliveryFineStatus === FineStatus.fined;
      report.totalMabaliDriverOrdersFined += finedDriver ? 1 : 0;
      report.totalMabaliDriverOrdersFinedMoney += finedDriver ? data.deliveryFineReward : 0;

      if (!finedDriver) {
        report.totalMabaliDriverOrdersMoney += data.deliveryFineReward;
      }
    }
    report.totalOrdersWithCashback += data.orderHasCashback > 0 ? 1 : 0;
    report.totalOrdersWithCashbackMoney += data.orderHasCashback;
    report.totalMabaliFeeMoney += data.mabaliCommission;

    report.totalOrdersUnder80 += data.total <= 80 ? 1 : 0;
    report.totalOrdersUnder80Money += data.total <= 80 ? data.total * 0.92 : 0;
    report.totalOrdersUnder120 += data.total > 80 && data.total <= 120 ? 1 : 0;
    report.totalOrdersUnder120Money += data.total > 80 && data.total <= 120 ? data.total * 0.90 : 0;
    report.totalOrdersOver120 += data.total > 120 ? 1 : 0;
    report.totalOrdersOver120Money += data.total > 120 ? data.total * 0.88 : 0;
    report.totalBusinessDeliveryFine += data.businessDeliveryFine || 0;


    return report;
  }

  const buildReportData = useCallback((newReport: any[], data: OrderDataType[]) => {
    const nonCanceledOrders = data.filter((order: OrderDataType) => {
      return order.status !== OrderStatus.canceled;
    })
    let ordersCount = nonCanceledOrders.length
    let deliveriesCount = nonCanceledOrders.filter((order: OrderDataType) => {
      return order.delivery;
    }).length
    const row = data.reduce(reduceFinancialReport, {
      id: `${data[0].restaurant_id}`,
      location_city: '',
      name: data[0].name,
      telephone: data[0]?.restaurant?.telephone,
      bolepodriver: '0',
      businessdriver: '0',
      newmembersclub: '0',
      tranzilacommission: 0,
      location: 0,
      businessCommission: 0,
      tabit: 0,
      coinsnis: 0,
      deliveryavg: 0,
      driverfee: 0,
      drivertip: 0,
      givencoins: 0,
      orderavg: 0,
      orderscount: ordersCount,
      deliveriescount: deliveriesCount,
      orderssum: 0,
      reviews: 0,
      smscost: 0,
      smscount: 0,
      totalcoins: 0,
      locationFee: 0,
      expressDeliveryFee: 0,
      businessDeliveryFee: 0,
      clientDeliveryFee: 0,
      business_coins_given: 0,
      takeout: 0,
      deliveryIncome: 0,
      takeoutIncome: 0,
      totalMabaliDriverOrders: 0,
      totalMabaliDriverOrdersMoney: 0,
      totalMabaliDriverOrdersFined: 0,
      totalMabaliDriverOrdersFinedMoney: 0,
      totalOrdersWithCashbackMoney: 0,
      totalOrdersUnder80: 0,
      totalOrdersUnder80Money: 0,
      totalOrdersUnder120: 0,
      totalOrdersUnder120Money: 0,
      totalOrdersOver120: 0,
      totalOrdersOver120Money: 0,
      businessRevenue: 0,
      totalBusinessDeliveryFine: 0,
      totalMabaliFeeMoney: 0,
    } as ReportDataType)

    row.businessRevenue += (
      (
        row.deliveryIncome +
        row.takeoutIncome
      )
      -
      (
        row.totalMabaliDriverOrdersMoney +
        row.totalMabaliDriverOrdersFinedMoney +
        row.totalMabaliDriverOrdersFinedMoney +
        row.totalBusinessDeliveryFine +
        row.totalMabaliFeeMoney
      )
    )

    newReport.push(row);
  }, [])


  useEffect(() => {
    let reportConsolidated: ReportDataType[] = [];
    console.log('showSummary', showSummary, restaurantId)
    if (showSummary) {
      const groupedRestaurant = groupBy(Report, (row: OrderDataType) => row.restaurant_id);

      reportConsolidated = Object.keys(groupedRestaurant).reduce((newReport: ReportDataType[], key: string) => {


        buildReportData(newReport, groupedRestaurant[key]);


        return newReport;
      }, [] as ReportDataType[])
    } else {

      Report.filter((order) => {
        console.log('order.restaurant_id', order.restaurant_id)
        return order.restaurant_id === restaurantId;
      }).forEach((row: OrderDataType) => {
        buildReportData(reportConsolidated, [row]);
      })
    }

    setReportPerBusiness(reportConsolidated);
  }, [Report, showSummary, restaurantId]);






  return (<div className="container-fluid page-body-wrapper">
    <div className="main-panel">
      <div className="" style={{
      }}>
        <div className="row">

          <div className="col-12 grid-margin">
            <div className="card" style={{

            }}>
              <div className="card-body" style={{
              }}>
                <h4 className="card-title">{i18n.t("financial-data")}</h4>
                <div className="card-description">
                  {/* <div className="form-group">
                                        <label htmlFor="exampleSelectGender">{i18n.t("business")}</label>
                                        <select className="form-control" id="exampleSelectGender" onChange={(e) => {
                                            console.log("onSelected Business", e.target.value)
                                            auth.user?.restaurants.forEach((restaurant: RestaurantType) => {
                                                //@ts-ignore
                                                if(restaurant.id == e.target.value)
                                                    setSelectedBusiness(restaurant)
                                            })
                                            
                                        }}>
                                            {auth.user?.restaurants && auth.user?.restaurants.map((restaurant: RestaurantType) => {
                                                return <option key={restaurant.id} value={restaurant.id}>{restaurant.name}</option>
                                            })}
                                        </select>
                                    </div> */}
                  <div style={{
                    display: "flex",
                    flexDirection: 'row'
                  }}>

                    <span style={{
                      marginLeft: 20,
                      marginRight: 20
                    }}>{i18n.t("start-date")}</span>
                    <DatePicker
                      onChange={(date: any) => {
                        console.log("change startDate", date)
                        onChangeStartDate(dayjs(date ? date : defaultStartDate))
                      }
                      }
                      value={startDate.toDate()}
                    />
                    <span style={{
                      marginLeft: 20,
                      marginRight: 20
                    }}>{i18n.t("end-date")}</span>
                    <DatePicker
                      onChange={(date: any) => {
                        console.log("onChange endDate", date)
                        onChangeEndDate(dayjs(date ? date : defaultEndDate))
                      }
                      }
                      value={endDate.toDate()}
                    />

                    <button className="btn btn-success" style={{
                      marginLeft: 20,
                      marginRight: 20
                    }} onClick={() => {

                      /* Bootstrap classes used to style and position the export button, i.e. when (`bootstrap: true` & `exportButtons: true`) */
                      TableExport.prototype.bootstrapConfig = ["btn", "btn-primary", "separate"];
                      //@ts-ignore
                      new TableExport(document.getElementsByTagName("table"), {
                        bootstrap: true,
                        RTL: true,
                        filename: "financial",
                        sheetname: "financial"
                      });
                    }} ><img src="https://unpkg.com/tableexport/dist/img/xlsx.svg" /> {i18n.t("export-xlsx")}</button>
                    <h2>₪{numeral(TotalOrders).format('0,0')}</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="" style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: 40,
                    border: '1px solid #333',
                    borderRadius: 5

                  }}>
                    <button onClick={() => setOrderType("current")} className={"btn " + (OrderType == "current" ? "btn-primary" : "btn-flat")}>{i18n.t("regular-order")}</button>
                    <button onClick={() => setOrderType("refunds")} className={"btn " + (OrderType == "refunds" ? "btn-primary" : "btn-flat")}>{i18n.t("refunds-order")}</button>
                  </div>
                </div>
                <div className="row" style={{
                  height: TableHeight,
                  overflowY: "scroll"
                }}>
                  <div className="table-sorter-wrapper col-lg-12 table-responsive" style={{
                    overflowX: 'visible'
                  }}>
                    <table id="sortable-table-1" className="table table-striped">
                      <thead>
                        <tr>
                          <th className="sortStyle">{i18n.t("name")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("orderscount")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{'סך כל משלוחים'}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-takeout")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-income-deliveries")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-income-takeout")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-mabali-drivers-order")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-for-mabali-drivers-money")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-order-with-driver-fine")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-drivers-fines-money")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-order-with-cashback")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-cashback-given")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("total-mabali-fee")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("orders-under-80")} <i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("order-under-80-value")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("order-under-120")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("order-under-120-value")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("order-over-120")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("order-over-120-value")}<i className="mdi mdi-chevron-down"></i></th>
                          <th className="sortStyle">{i18n.t("business-revenue")}<i className="mdi mdi-chevron-down"></i></th>

                        </tr>
                      </thead>
                      <tbody>
                        {reportPerBusiness.map((data: ReportDataType, index: number) => {

                          return <tr key={index} style={{
                            cursor: 'pointer'
                          }} onClick={() => showRestaurantDetails(Number(data.id))}>
                            <td>{data.name}</td>
                            <td>{numeral(data.orderscount).format('0,0')}</td>
                            <td>{numeral(data.deliveriescount).format('0,0')}</td>
                            <td>{numeral(data.takeout).format('0,0')}</td>
                            <td>{numeral(data.deliveryIncome).format("$0,0.00")}</td>
                            <td>{numeral(data.takeoutIncome).format("$0,0.00")}</td>
                            <td>{numeral(data.totalMabaliDriverOrders).format("0,0")}</td>
                            <td>{numeral(data.totalMabaliDriverOrdersMoney).format("$0,0")}</td>
                            <td>{numeral(data.totalMabaliDriverOrdersFined).format("0,0")}</td>
                            <td>{numeral(data.totalMabaliDriverOrdersFinedMoney).format("$0,0")}</td>
                            <td>{numeral(data.totalOrdersWithCashback).format("0,0")}</td>
                            <td>{numeral(data.totalOrdersWithCashbackMoney).format("0,0")}</td>
                            <td>{numeral(data.totalMabaliFeeMoney).format("$0,0")}</td>
                            <td>{numeral(data.totalOrdersUnder80).format("0,0")}</td>
                            <td>{numeral(data.totalOrdersUnder80Money).format("$0,0")}</td>
                            <td>{numeral(data.totalOrdersUnder120).format("0,0")}</td>
                            <td>{numeral(data.totalOrdersUnder120Money).format("$0,0")}</td>
                            <td>{numeral(data.totalOrdersOver120).format("0,0")}</td>
                            <td>{numeral(data.totalOrdersOver120Money).format("$0,0")}</td>
                            <td>{numeral(data.businessRevenue).format("$0,0")}</td>



                          </tr>
                        })}



                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <style>
      {`
                .separate > button {
                    margin-left : 5px;
                    margin-right : 5px;
                }
            `}
    </style>
  </div>

  )
}

export default Financial
