
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { RootState } from '../configureStore'
import i18n from '../i18n'
import { logout } from '../redux/authSlice'
import { api, clearJwtToken } from '../utils/api'
import { MabaliColors } from '../utils/Colors'

const TopBar = () => {
    const dispatch = useDispatch()
    const auth = useSelector((state: RootState) => state.auth)
    const history = useHistory()

    const location = useLocation()

    

    const doLogout = () => {
        dispatch(logout())
        clearJwtToken()
        history.replace("/login")
    }

    if (!auth.user) {
        return null
    }


    const getActiveLink = (path:string) => {

        if (path == location.pathname)
            return " active-link"
        return ""
    }

    return (
        <div className="horizontal-menu">
            <nav className="navbar top-navbar col-lg-12 col-12 p-0" style={{
                backgroundColor : MabaliColors.pink
            }}>
                <div className="container">
                    <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center" style={{
                        display : 'flex',
                        justifyContent : 'center',
                        alignItems : 'center'
                    }}>
                        <a className="navbar-brand brand-logo" href="index.html">
                            <img src="/images/appstore.png" alt="logo" style={{
                                borderRadius: 6
                            }} /></a>
                        <h1 style={{
                            fontSize : 20,
                            color : "#fff",
                            marginLeft : 10,
                            marginRight : 10,
                            padding : 0
                        }}>ניהול מערכת</h1>
                    </div>
                    <div className="navbar-menu-wrapper d-flex align-items-center">

                        <ul className="navbar-nav navbar-nav-right">
                            {/* <li className="nav-item dropdown mr-1">
                                    <a className="nav-link count-indicator dropdown-toggle d-flex justify-content-center align-items-center" id="messageDropdown" href="#" data-toggle="dropdown">
                                        <i className="mdi mdi-email mx-0"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="messageDropdown">
                                        <p className="mb-0 font-weight-normal float-left dropdown-header">Messages</p>
                                        <a className="dropdown-item preview-item">
                                            <div className="preview-thumbnail">
                                                <img src="https://via.placeholder.com/36x36" alt="image" className="profile-pic" />
                                            </div>
                                            <div className="preview-item-content flex-grow">
                                                <h6 className="preview-subject ellipsis font-weight-normal">David Grey
                                                </h6>
                                                <p className="font-weight-light small-text text-muted mb-0">
                                                    The meeting is cancelled
                                                </p>
                                            </div>
                                        </a>
                                        <a className="dropdown-item preview-item">
                                            <div className="preview-thumbnail">
                                                <img src="https://via.placeholder.com/36x36" alt="image" className="profile-pic " />
                                            </div>
                                            <div className="preview-item-content flex-grow">
                                                <h6 className="preview-subject ellipsis font-weight-normal">Tim Cook
                                                </h6>
                                                <p className="font-weight-light small-text text-muted mb-0">
                                                    New product launch
                                                </p>
                                            </div>
                                        </a>
                                        <a className="dropdown-item preview-item">
                                            <div className="preview-thumbnail">
                                                <img src="https://via.placeholder.com/36x36" alt="image" className="profile-pic" />
                                            </div>
                                            <div className="preview-item-content flex-grow">
                                                <h6 className="preview-subject ellipsis font-weight-normal"> Johnson
                                                </h6>
                                                <p className="font-weight-light small-text text-muted mb-0">
                                                    Upcoming board meeting
                                                </p>
                                            </div>
                                        </a>
                                    </div>
                                </li>
                                <li className="nav-item dropdown mr-4">
                                    <a className="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center" id="notificationDropdown" href="#" data-toggle="dropdown">
                                        <i className="mdi mdi-bell mx-0"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
                                        <p className="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>
                                        <a className="dropdown-item preview-item">
                                            <div className="preview-thumbnail">
                                                <div className="preview-icon bg-success">
                                                    <i className="mdi mdi-information mx-0"></i>
                                                </div>
                                            </div>
                                            <div className="preview-item-content">
                                                <h6 className="preview-subject font-weight-normal">Application Error</h6>
                                                <p className="font-weight-light small-text mb-0 text-muted">
                                                    Just now
                                                </p>
                                            </div>
                                        </a>
                                        <a className="dropdown-item preview-item">
                                            <div className="preview-thumbnail">
                                                <div className="preview-icon bg-warning">
                                                    <i className="mdi mdi-settings mx-0"></i>
                                                </div>
                                            </div>
                                            <div className="preview-item-content">
                                                <h6 className="preview-subject font-weight-normal">Settings</h6>
                                                <p className="font-weight-light small-text mb-0 text-muted">
                                                    Private message
                                                </p>
                                            </div>
                                        </a>
                                        <a className="dropdown-item preview-item">
                                            <div className="preview-thumbnail">
                                                <div className="preview-icon bg-info">
                                                    <i className="mdi mdi-account-box mx-0"></i>
                                                </div>
                                            </div>
                                            <div className="preview-item-content">
                                                <h6 className="preview-subject font-weight-normal">New user registration</h6>
                                                <p className="font-weight-light small-text mb-0 text-muted">
                                                    2 days ago
                                                </p>
                                            </div>
                                        </a>
                                    </div>
                                </li> */}
                            <li className="nav-item nav-profile dropdown mr-0 mr-sm-2">
                                <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
                                    <img src={auth.user.avatar ? api.getMedia!(auth.user.avatar) : "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"} alt="" />
                                    
                                    <span className="nav-profile-name">{i18n.t("hello").format(auth.user.firstName)}</span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                                    {/* <a className="dropdown-item">
                                            <i className="mdi mdi-settings text-primary"></i>
                                            Settings
                                        </a> */}
                                    <a className="dropdown-item" onClick={doLogout}>
                                        <i className="mdi mdi-logout text-primary"></i>
                                        {i18n.t("logout")}
                                    </a>
                                </div>
                            </li>
                        </ul>
                        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="horizontal-menu-toggle">
                            <span className="mdi mdi-menu"></span>
                        </button>
                    </div>
                </div>
            </nav>
            <nav className="bottom-navbar">
                <div className="container">
                    <ul className="nav page-navigation">
                        <li className={"nav-item" + getActiveLink("/")}>
                            <a className={"nav-link"} style={{

                            }} onClick={() => history.push("/")} href="#">
                                <i className="mdi mdi-home-outline menu-icon"></i>
                                <span className="menu-title">{i18n.t("dashboard")}</span>
                            </a>
                        </li>
                        {/* <li className={"nav-item" + getActiveLink("/sms-campaign")}>
                            <a className={"nav-link"} style={{

                            }} onClick={() => history.push("/sms-campaign")} href="#">
                                <i className="mdi mdi-message-text-outline menu-icon"></i>
                                <span className="menu-title">{i18n.t("sms-campaign")}</span>
                            </a>
                        </li> */}
                        <li className={"nav-item" + getActiveLink("/financial")}>
                            <a className="nav-link" href="#" onClick={() => history.push("/financial")}>
                                <i className="mdi mdi-newspaper menu-icon"></i>
                                <span className="menu-title">{i18n.t("business-financial")}</span>
                            </a>
                        </li>

                        <li className={"nav-item" + getActiveLink("/bolepo-reviews")}>
                            <a className="nav-link" href="#" onClick={() => history.push("/bolepo-reviews")}>
                                <i className="mdi mdi-comment-account-outline menu-icon"></i>
                                <span className="menu-title">{i18n.t("bolepo-reviews")}</span>
                            </a>
                        </li>
                        <li className={"nav-item" + getActiveLink("/business-reviews")}>
                            <a className="nav-link" href="#" onClick={() => history.push("/business-reviews")}>
                                <i className="mdi mdi-comment-account menu-icon"></i>
                                <span className="menu-title">{i18n.t("business-reviews")}</span>
                            </a>
                        </li>
                        <li className={"nav-item" + getActiveLink("/drivers-reviews")}>
                            <a className="nav-link" href="#" onClick={() => history.push("/drivers-reviews")}>
                                <i className="mdi mdi-comment-account menu-icon"></i>
                                <span className="menu-title">{i18n.t("drivers-reviews")}</span>
                            </a>
                        </li>

                        <li className={"nav-item" + getActiveLink("/customer")}>
                            <a className="nav-link" href="#" onClick={() => history.push("/customer")}>
                                <i className="mdi mdi-account-box menu-icon"></i>
                                <span className="menu-title">{i18n.t("customers")}</span>
                            </a>
                        </li>
                        <li className={"nav-item" + getActiveLink("/orders")}>
                            <a className="nav-link" href="#" onClick={() => history.push("/orders")}>
                                <i className="mdi mdi-codepen menu-icon"></i>
                                <span className="menu-title">{i18n.t("orders")}</span>
                            </a>
                        </li>
                        <li className={"nav-item" + getActiveLink("/deliveries")}>
                            <a className="nav-link" href="#" onClick={() => history.push("/deliveries")}>
                                <i className="mdi mdi-finance menu-icon"></i>
                                <span className="menu-title">משלוחים</span>
                            </a>
                        </li>


                    </ul>
                </div>
            </nav>
            <style>
                {`
                    .active-link {
                        border-bottom : 4px solid #405189;
                    }
                    `}
            </style>
        </div>
    )
}

export default TopBar
