import { useCallback, useEffect, useState } from 'react'
import { api } from '../../../../utils/api';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import dayjs from 'dayjs';
import { Range, Value, ValuePiece } from '../../Main';
import qs from 'qs';
import numeral from 'numeral';
import { OrderDataType } from '../../../Financial/orders-financial.types';
import { useQuery } from '../../../../utils/router';

const OrdersPaidWith = () => {

  //Get query params paidWithCoins
  const query = useQuery();

  const paidWithCoins = query.get('paidWithCoins') === 'true';
  const [transactions, setTransactions] = useState<{ id: string; name: string; numberOrders: number; total: number; }[]>([]);
  const [value, onChange] = useState<Value>([dayjs().subtract(30, 'day').toDate(), dayjs().toDate()]);

  const getOrderContributions = useCallback(async () => {
    const params = qs.stringify({
      _where: {
        paid: true,
        paidWithCoins: paidWithCoins,
        created_at_gte: dayjs(value[0]).format("YYYY-MM-DD"),
        created_at_lte: dayjs(value[1]).format("YYYY-MM-DD"),
      }

    })
    const { data: orders } = await api.get<OrderDataType[]>(`/orders?${params}`)
    console.log('orders', orders)
    const groupedOrders = orders.reduce((group, order) => {
      const restaurantId = order.restaurant_id;
      group[restaurantId] = group[restaurantId] || [];
      group[restaurantId].push({
        average: 0,
        ...order
      });
      return group
    }, {} as { [key: string]: (OrderDataType & { average: number })[] })

    const businessAverages: { id: string; name: string; numberOrders: number; total: number; }[] = []
    Object.keys(groupedOrders).forEach((restaurantId) => {
      const orders = groupedOrders[restaurantId];
      const total = orders.reduce((total, order) => {
        return total + order.total;
      }, 0)

      businessAverages.push({
        id: restaurantId,
        name: orders[0].restaurant?.name || '',
        numberOrders: orders.length,
        total,
      })
    })

    setTransactions(businessAverages);
  }, [value, paidWithCoins]);


  useEffect(() => {
    getOrderContributions()
  }, [getOrderContributions])
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'center',
      paddingLeft: 20,
      paddingTop: 20,
    }} className=''>
      <h1 className='h1'>Orders Paid With {paidWithCoins ? 'Coins' : 'Cash'}</h1>
      <div className="row" style={{
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '30px',
        zIndex: 99999
      }}>
        <DateRangePicker
          maxDate={dayjs().toDate()}
          className={"daterange-picker"}
          onChange={(value: any) => {
            onChange(value as Range<ValuePiece>);
          }} value={value} />
        <button className="btn btn-primary" onClick={() => {
          getOrderContributions()
        }}>Search</button>
      </div>
      <table className="table table-striped" >
        <thead>
          <tr>
            <th scope="col">Business</th>
            <th scope="col">Total Orders</th>
            <th scope="col">Amount</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => {

            return (
              <tr key={transaction.id}>

                <td>{transaction.name}</td>
                <td>{numeral(transaction.numberOrders).format('0,0')}</td>
                <td>{numeral(transaction.total).format('$0,0.00')}</td>

              </tr>
            )
          })}
        </tbody>
      </table>
      <div style={{
        minHeight: '300px',
      }} />
    </div>
  )
}

export default OrdersPaidWith