//@ts-nocheck
import React, { useEffect, useState } from 'react'
import i18n from '../../i18n';
import { api } from '../../utils/api';



const DistributionChart = () => {

    const [AddressDistribution, setAddressDistribution] = useState([])

    const getDistribution = () => {
        api.get("/addresses/get-distribution")
            .then(({ data }) => {
                console.log("get-distribution", data)
                setAddressDistribution(data)
            })
            .catch((e) => {
                console.warn("could not get distrubution", e)
            })
    }

    useEffect(() => {
        getDistribution()

    }, [])

    useEffect(() => {

        if(AddressDistribution.length == 0)
            return;

        if ($("#distribution-chart").length) {
            let labels = []
            let data = []
            let backgroundColor  = []
            let colorMap = {}
            AddressDistribution.forEach((address) => {
                var randomColor = "#" + Math.floor(Math.random()*16777215).toString(16);
                colorMap[address.location] = randomColor

                labels.push(address.city)
                data.push(address.count)
                backgroundColor.push(randomColor)
               
                
            })
            var areaData = {
                labels: labels,
                datasets: [{
                    data: data,
                    backgroundColor: backgroundColor,
                    borderColor: "rgba(0,0,0,0)"
                }
                ]
            };
            
            var areaOptions = {
                responsive: true,
                maintainAspectRatio: true,
                segmentShowStroke: false,
                cutoutPercentage: 72,
                elements: {
                    arc: {
                        borderWidth: 4
                    }
                },
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: true
                },
                legendCallback: function (chart) {
                    var text = [];
                    text.push('<div class="distribution-chart">');
                    

                    AddressDistribution.forEach((address) => {
                        text.push('<div class="item"><div class="legend-label" style="border: 3px solid ' + colorMap[address.location] + '"></div>');
                            text.push('<p>' + address.city + '</p>');
                        text.push('</div>');
                        
                    })
                    text.push('</div>');


                    return text.join("");
                },
            }
            var distributionChartPlugins = {
                beforeDraw: function (chart) {
                    var width = chart.chart.width,
                        height = chart.chart.height,
                        ctx = chart.chart.ctx;

                    ctx.restore();
                    var fontSize = .96;
                    ctx.font = "600 " + fontSize + "em sans-serif";
                    ctx.textBaseline = "middle";
                    ctx.fillStyle = "#000";

                    var text = "",
                        textX = Math.round((width - ctx.measureText(text).width) / 2),
                        textY = height / 2;

                    ctx.fillText(text, textX, textY);
                    ctx.save();
                }
            }
            var distributionChartCanvas = $("#distribution-chart").get(0).getContext("2d");
            var distributionChart = new Chart(distributionChartCanvas, {
                type: 'doughnut',
                data: areaData,
                options: areaOptions,
                plugins: distributionChartPlugins
            });
            document.getElementById('distribution-legend').innerHTML = distributionChart.generateLegend();
        }
        
    }, [AddressDistribution])
    return (
        <div className="col-xl-3 grid-margin stretch-card">
            <div className="card">
                <div className="card-body pb-0">
                    <p className="card-title mb-xl-0">{i18n.t("distribution")}</p>
                </div>
                <canvas id="distribution-chart"></canvas>
                <div className="card-body">
                    <div id="distribution-legend" className="distribution-chart-legend pt-4 pb-3"></div>
                </div>
            </div>
        </div>
    )
}

export default DistributionChart
