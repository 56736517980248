import { useContext, useEffect, useState } from 'react'
import { api } from '../../utils/api';
import dayjs from 'dayjs'
import DatePicker from 'react-date-picker';
import i18n from '../../i18n';
import { useSelector } from 'react-redux';
import { RootState } from '../../configureStore';
import { TableExport } from 'tableexport';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import numeral from 'numeral'

import { OrderDataType, OrderPaymentObj } from './orders-financial.types';
import { MabaliColors } from '../../utils/Colors';
import React from 'react';

const loadScript = function (src: string, id: string) {
    var tag = window.document.createElement('script');
    tag.async = false;
    tag.src = src;
    tag.id = id
    let previousTag = window.document.getElementById(id)
    if (previousTag)
        window.document.getElementsByTagName('body')[0].removeChild(previousTag)
    window.document.getElementsByTagName('body')[0].appendChild(tag);
}


const defaultStartDate = dayjs().add(-30, 'day')
const defaultEndDate = defaultStartDate.add(30, 'day')


export const getOrderType = (type: string | any) => {
    switch (type) {
        case 'delivery':
            return "משלוח"
        case 'takeout':
            return "איסוף עצמי";
        case 'scheduleddelivery':
            return "הזמנה מתוזמנת";
    }
}

const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const FinancialDetails = () => {
    const history = useHistory()
    const [TableHeight, setTableHeight] = useState<any>("50vh")
    const [SelectedBusiness, setSelectedBusiness] = useState<string>("")

    const { restaurantId } = useParams<{ restaurantId: string }>()


    const [Report, setReport] = useState<OrderDataType[]>([])
    const [ReportNotPaid, setReportNotPaid] = useState<OrderDataType[]>([])
    const [DOMLoaded, setDOMLoaded] = useState(false)
    const [OrderType, setOrderType] = useState("current")
    const [GlobalConfig, setGlobalConfig] = useState<any>(null);



    const getGlobalConfig = () => {
        api.get("/global-configs")
            .then(({ data }) => {

                data.totalContributions = 0

                data.totalContributions += data.businessCommission
                data.totalContributions += data.mabaliCommission
                data.totalContributions += data.communityCommission
                setGlobalConfig(data)
            })
    }

    useEffect(() => {
        setDOMLoaded(true)
        getGlobalConfig()
    }, [])

    useEffect(() => {

        if (DOMLoaded) {
            //@ts-ignore
            loadScript('/js/jq.tablesort.js', "tablesort")
            loadScript('/js/tablesorter.js', "tablesorter")
        }
    }, [DOMLoaded])


    const auth = useSelector((state: RootState) => state.auth)

    const getFinancialReport = () => {
        api.get(`/restaurants/orders?restaurant=${restaurantId}&start=${startDate.format("YYYY-MM-DD")}&end=${endDate.format("YYYY-MM-DD")}&type=${OrderType}`)
            .then(({ data }: { data: OrderDataType[] }) => {
                console.log("got report", data)

                setReport(data.filter((order) => order.paid))
                setReportNotPaid(data.filter((order) => !order.paid))
            })
            .catch((e) => {
                console.log("could not get financial", e)
            })
    }



    const [startDate, onChangeStartDate] = useState(dayjs().add(-30, 'day'));
    const [endDate, onChangeEndDate] = useState(startDate.add(30, 'day'));

    // useEffect(() => {
    //     if (auth.user && auth.user.restaurants.length){
    //         setSelectedBusiness(auth.user?.restaurants.map((resta :any) => resta.id).join(","))
    //     }
    // }, [auth])


    useEffect(() => {
        // if(DOMLoaded)
        //     loadScript('/js/jq.tablesort.js', "tablesort")
        //     loadScript('/js/tablesorter.js', "tablesorter")
    }, [Report, DOMLoaded])



    useEffect(() => {
        console.log({ restaurantId });
        if (restaurantId)
            getFinancialReport()

    }, [restaurantId, startDate, endDate, OrderType])

    useEffect(() => {

        let cardTitle = document.getElementsByClassName("card-title")[0].clientHeight
        let cardDescription = document.getElementsByClassName("card-title")[0].clientHeight
        let cardBody = document.getElementsByClassName("card-body")[0].clientHeight
        console.log("cardBody", cardBody)
        setTableHeight((cardBody - cardTitle - cardDescription))

    }, [])

    let TotalOrders = 0

    Report.map((row) => {
        let businessWillReceive = 0
        if (row.bolepoDriver) {
            businessWillReceive = row.total - row.clientDeliveryFee - row.businessDeliveryFee - row.mabaliCommission - row.business_coins_given
        } else {
            businessWillReceive = row.total + row.clientDeliveryFee - row.mabaliCommission - row.business_coins_given
        }
        TotalOrders += businessWillReceive
    })

    let summary = {
        totalSpent: 0,
        totalCommission: 0,
        totalMabaliCommission: 0,
        totalCommunityCommission: 0,
        totalBusinessCommission: 0,
        totalTranzilaCommission: 0,
        cashback: 0,
        compensationRefund: 0,
        finalIncome: 0
    }


    return (<div className="container-fluid page-body-wrapper">
        <div className="main-panel">
            <div className="">
                <div className="row">

                    <div className="col-12 grid-margin">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">{i18n.t("orders-data")}</h4>
                                <div className="card-description">
                                    {/* <div className="form-group">
                                        <label htmlFor="exampleSelectGender">{i18n.t("business")}</label>
                                        <select className="form-control" id="exampleSelectGender" onChange={(e) => {
                                            console.log("onSelected Business", e.target.value)
                                            if(e.target.value == "0"){
                                                let selectedIds = ""
                                                auth.user?.restaurants.forEach((restaurant: RestaurantType) => {
                                                    if(selectedIds){
                                                        selectedIds += ","
                                                    }
                                                    selectedIds += restaurant.id
                                                })
                                                setSelectedBusiness(selectedIds)
                                            }else{
                                                auth.user?.restaurants.forEach((restaurant: RestaurantType) => {
                                                    //@ts-ignore
                                                    if (restaurant.id == e.target.value)
                                                        setSelectedBusiness(restaurant.id)
                                                })
    
                                            }

                                        }}>
                                             <option value={0} >{i18n.t("all")}</option>
                                            {auth.user?.restaurants && auth.user?.restaurants.map((restaurant: RestaurantType) => {
                                                return <option key={restaurant.id} value={restaurant.id}>{restaurant.name}</option>
                                            })}
                                        </select>
                                    </div> */}
                                    <div style={{
                                        display: "flex",
                                        flexDirection: 'row'
                                    }}>

                                        <span style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }}>{i18n.t("start-date")}</span>
                                        <DatePicker

                                            onChange={(date: any) => {
                                                console.log("change startDate", date)
                                                onChangeStartDate(dayjs(date ? date : defaultStartDate))
                                            }
                                            }
                                            value={startDate.toDate()}
                                        />
                                        <span style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }}>{i18n.t("end-date")}</span>
                                        <DatePicker
                                            onChange={(date: any) => {
                                                console.log("onChange endDate", date)
                                                onChangeEndDate(dayjs(date ? date : defaultEndDate))
                                            }
                                            }
                                            value={endDate.toDate()}
                                        />

                                        <button className="btn btn-success" style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }} onClick={() => {

                                            /* Bootstrap classes used to style and position the export button, i.e. when (`bootstrap: true` & `exportButtons: true`) */
                                            TableExport.prototype.bootstrapConfig = ["btn", "btn-primary", "separate"];
                                            //@ts-ignore
                                            new TableExport(document.getElementsByTagName("table"), {
                                                bootstrap: true,
                                                RTL: true,
                                                filename: "orders",
                                                sheetname: "orders"
                                            });
                                        }} ><img src="https://unpkg.com/tableexport/dist/img/xlsx.svg" /> {i18n.t("export-xlsx")}</button>
                                        <h2>₪{numeral(TotalOrders).format('0,0')}</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="" style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        height: 40,
                                        border: '1px solid #333',
                                        borderRadius: 5

                                    }}>
                                        <button onClick={() => setOrderType("current")} className={"btn " + (OrderType == "current" ? "btn-primary" : "btn-flat")}>{i18n.t("regular-order")}</button>
                                        <button onClick={() => setOrderType("refunds")} className={"btn " + (OrderType == "refunds" ? "btn-primary" : "btn-flat")}>{i18n.t("refunds-order")}</button>
                                    </div>
                                </div>
                                <div className="row" style={{
                                    height: TableHeight,
                                    overflowY: "scroll"
                                }}>
                                    <div className="table-sorter-wrapper col-lg-12 table-responsive" style={{
                                        overflowX: 'visible'
                                    }}>
                                        <table id="sortable-table-1" className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="sortStyle">{i18n.t("customer-name")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("date-time")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{'מס׳ הזמנה'}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{'סוג הזמנה'}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{'סוג שליח'}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{'סכום הזמנה'}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{'דמי משלוח - לקוח'}<i className="mdi mdi-chevron-down"></i></th>
                                                    {/* Total Order */}
                                                    <th className="sortStyle">{'סה"כ הזמנה'}<i className="mdi mdi-chevron-down"></i></th>
                                                    {/* Paid with CC */}
                                                    <th className="sortStyle">{'בתשלום באמצעות CC'}<i className="mdi mdi-chevron-down"></i></th>
                                                    {/* Paid with mabali coins */}
                                                    <th className="sortStyle">{'Paid with Mabali Coins'}<i className="mdi mdi-chevron-down"></i></th>
                                                    {/* Paid with business coins */}
                                                    <th className="sortStyle">{'Paid with Business Coins'}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{'דמי משלוח - עסק'}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{'עמלת מבאלי'}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{'החזר כספי'}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{'זיכוי/פיצוי בש"ח'}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{'הכנסה סופית - עסק'}<i className="mdi mdi-chevron-down"></i></th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Report.map((data: OrderDataType, index: number) => {

                                                    summary.totalCommission += data.mabaliCommission + data.businessCommission + data.communityCommission
                                                    summary.totalMabaliCommission += data.mabaliCommission
                                                    summary.totalSpent += data.total
                                                    summary.cashback += data.business_coins_given
                                                    summary.compensationRefund += data.refunded ? data.total : 0

                                                    let businessWillReceive = 0



                                                    const paymentMethods = data.paymentObj ? Object.values<OrderPaymentObj>(data.paymentObj) : [];
                                                    const creditCardPaid = paymentMethods.filter((payment) => ['card', 'money'].includes(payment.type)).reduce((amount, payment) => {
                                                        return amount + payment.amount;
                                                    }, 0)
                                                    const mabaliCoinsPaid = paymentMethods
                                                        .filter((payment) => ['coin'].includes(payment.type))
                                                        .filter((payment) => payment.restaurant == null)
                                                        .reduce((amount, payment) => {
                                                            return amount + payment.amount;
                                                        }, 0)
                                                    const businessCoinsPaid = paymentMethods
                                                        .filter((payment) => ['coin'].includes(payment.type))
                                                        .filter((payment) => payment.restaurant != null)
                                                        .reduce((amount, payment) => {
                                                            return amount + payment.amount;
                                                        }, 0)

                                                    const businessFine = data.fineTime > 0 ? data.fineTime : 0;

                                                    if (data.bolepoDriver) {
                                                        businessWillReceive = data.total - data.clientDeliveryFee - data.businessDeliveryFee - data.mabaliCommission - data.business_coins_given
                                                    } else {
                                                        businessWillReceive = data.total + data.clientDeliveryFee - data.mabaliCommission - data.business_coins_given
                                                    }

                                                    businessWillReceive -= businessFine;

                                                    summary.finalIncome += businessWillReceive;

                                                    return <tr key={index}>

                                                        <td>{data.fullname}</td>
                                                        <td>{dayjs(data.orderDate).format('DD/MM/YY HH:mm')}</td>
                                                        <td>{data.id}</td>
                                                        <td>{getOrderType(data.orderType)}</td>
                                                        <td>{(data.mabaliDriver ? "שליח מהבאלי" : "שליח עסק")}</td>
                                                        <td>₪{numeral(data.total).format("0,0.00")}</td>
                                                        <td>₪{numeral(data.clientDeliveryFee).format("0,0.00")}</td>
                                                        {/* Total Order */}
                                                        <td>₪{numeral(data.total + data.clientDeliveryFee).format("0,0.00")}</td>
                                                        {/* Paid with CC */}
                                                        <td>₪{`${numeral(creditCardPaid).format("0,0.00")}`}</td>
                                                        {/* Paid with mabali coins */}
                                                        <td>₪{`${numeral(mabaliCoinsPaid).format("0,0.00")}`}</td>
                                                        {/* Paid with business coins */}
                                                        <td>₪{`${numeral(businessCoinsPaid).format("0,0.00")}`}</td>
                                                        <td>₪{numeral(data.shippingCost - data.clientDeliveryFee).format("0,0.00")}</td>
                                                        <td>₪{numeral(data.mabaliCommission).format("0,0.00")}</td>

                                                        <td>₪{numeral(data.refunded ? data.total : 0).format("0,0.00")}</td>
                                                        <td>₪{numeral(data.business_coins_given).format("0,0.00")}</td>
                                                        <td>₪{numeral(businessWillReceive).format("0,0.00")}</td>


                                                    </tr>
                                                })}
                                                {ReportNotPaid.length > 0 ? <tr>
                                                    <td colSpan={16} style={{
                                                        backgroundColor: MabaliColors.darkGray,
                                                        color: 'white'
                                                    }}>Incompleted</td>
                                                </tr> : null}
                                                {ReportNotPaid.map((data: OrderDataType, index: number) => {

                                                    // summary.totalCommission += data.mabaliCommission + data.businessCommission + data.communityCommission
                                                    // summary.totalMabaliCommission += data.mabaliCommission
                                                    // summary.totalSpent += data.total
                                                    // summary.cashback += data.business_coins_given
                                                    // summary.compensationRefund += data.refunded ? data.total : 0

                                                    let businessWillReceive = 0

                                                    if (data.bolepoDriver) {
                                                        businessWillReceive = data.total - data.clientDeliveryFee - data.businessDeliveryFee - data.mabaliCommission - data.business_coins_given
                                                    } else {
                                                        businessWillReceive = data.total + data.clientDeliveryFee - data.mabaliCommission - data.business_coins_given
                                                    }
                                                    //summary.finalIncome += businessWillReceive;

                                                    const paymentMethods = data.paymentObj ? Object.values<OrderPaymentObj>(data.paymentObj) : [];
                                                    const creditCardPaid = paymentMethods.filter((payment) => ['card', 'money'].includes(payment.type)).reduce((amount, payment) => {
                                                        return amount + payment.amount;
                                                    }, 0)
                                                    const mabaliCoinsPaid = paymentMethods
                                                        .filter((payment) => ['coin'].includes(payment.type))
                                                        .filter((payment) => payment.restaurant == null)
                                                        .reduce((amount, payment) => {
                                                            return amount + payment.amount;
                                                        }, 0)
                                                    const businessCoinsPaid = paymentMethods
                                                        .filter((payment) => ['coin'].includes(payment.type))
                                                        .filter((payment) => payment.restaurant != null)
                                                        .reduce((amount, payment) => {
                                                            return amount + payment.amount;
                                                        }, 0)
                                                    return <tr key={index} style={{
                                                        backgroundColor: MabaliColors.red,
                                                        color: MabaliColors.white
                                                    }}>

                                                        <td>{data.fullname}</td>
                                                        <td>{dayjs(data.orderDate).format('DD/MM/YY HH:mm')}</td>
                                                        <td>{data.id}</td>
                                                        <td>{getOrderType(data.orderType)}</td>
                                                        <td>{(data.mabaliDriver ? "שליח מהבאלי" : "שליח עסק")}</td>
                                                        {/* Total Order */}
                                                        <td>₪{numeral(data.total).format("0,0.00")}</td>
                                                        <td>₪{numeral(data.clientDeliveryFee).format("0,0.00")}</td>
                                                        {/* Total Order */}
                                                        <td>₪{numeral(0).format("0,0.00")}</td>
                                                        {/* Paid with CC */}
                                                        <td>₪{`${numeral(0).format("0,0.00")}`}</td>
                                                        {/* Paid with mabali coins */}
                                                        <td>₪{`${numeral(0).format("0,0.00")}`}</td>
                                                        {/* Paid with business coins */}
                                                        <td>₪{`${numeral(0).format("0,0.00")}`}</td>
                                                        <td>₪{numeral(0).format("0,0.00")}</td>
                                                        <td>₪{numeral(0).format("0,0.00")}</td>
                                                        <td>₪{numeral(data.business_coins_given).format("0,0.00")}</td>
                                                        <td>₪{numeral(data.refunded ? data.total : 0).format("0,0.00")}</td>
                                                        <td>₪{numeral(businessWillReceive).format("0,0.00")}</td>


                                                    </tr>
                                                })}

                                                {/* <tr style={{
                                                    fontWeight: 800
                                                }} >
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>₪{numeral(summary.totalSpent).format("0,0.00")}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>₪{numeral(summary.totalCommission).format("0,0.00")}</td>

                                                    <td>₪{numeral(summary.cashback).format("0,0.00")}</td>
                                                    <td>₪{numeral(summary.compensationRefund).format("0,0.00")}</td>
                                                    <td>₪{numeral(summary.finalIncome).format("0,0.00")}</td>
                                                    <td></td>
                                                    <td></td>


                                                </tr> */}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <style>
            {`
                .separate > button {
                    margin-left : 5px;
                    margin-right : 5px;
                }
            `}
        </style>
    </div>

    )
}

export default FinancialDetails
