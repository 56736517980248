import React, { useState, useEffect } from 'react'
import {
  useParams
} from "react-router-dom";
import { api } from '../../utils/api';
import { Color } from '../../utils/Colors';
import ChatBody from './ChatBody';
import ChatTopOrder from './ChatTopOrder';
import MessageInput from './MessageInput';
import {
  useLocation
} from "react-router-dom";
import { ChatType } from './OrderChat';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}



export function DriverChat() {
  const [messages, setMessages] = useState([]);
  const [CurrentChat, setCurrentChat] = useState<ChatType | null>(null);
  const [CurrentOrder, setCurrentOrder] = useState<any>(null)

  let { id }: any = useParams();
  let query = useQuery();

  const driverId = (Number)(query.get('driverId'));
  const apiKey = (query.get('apiKey'));

  console.log('apiKey', apiKey)
  console.log('api.defaults.headers', api.defaults)

  const securityHeaders = apiKey ? {
    withCredentials: false,
    headers: {
      'x-clientsecret': apiKey
    }
  } : api.defaults.headers

  const getUserChat = () => {
    api.get(`/chat-agents/${CurrentOrder?.chat_agent?.id}?driverChat=true`, securityHeaders)
      .then(({ data }) => {
        console.log("data", data)
        const currentChat = data
        setCurrentChat(currentChat);
        setMessages(currentChat.chat || [])

      })
      .catch((e) => {
        console.warn("could not get messages", e.response.data);
      })
  }
  useEffect(() => {
    if (CurrentOrder?.chat_agent)
      getUserChat();
  }, [CurrentOrder])

  const getOrder = () => {
    api.get('/orders/' + id, securityHeaders)
      .then(({ data }) => {
        setCurrentOrder(data);
      })
  }

  useEffect(() => {
    getOrder()
  }, [id]);


  return (
    <div className='container-fluid' style={{
      flex: 1,
      // height: "90vh",
      width: "100%",
      // overflowY: 'scroll',
      display: 'flex',
      padding: 0,
      flexDirection: 'column',
      //  height :"90vh",
      backgroundColor: Color.lightGray
    }}>
      <ChatTopOrder order={CurrentOrder} />
      <ChatBody messages={CurrentChat?.chat} clientId={driverId} />
      
    </div>
  )
}

export default DriverChat