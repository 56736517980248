import React, { useCallback, useEffect, useState } from 'react'
import { api } from '../../../../utils/api';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import dayjs from 'dayjs';
import { Range, Value, ValuePiece } from '../../Main';
import qs from 'qs';
import { MembersClubBalanceHistory } from './CounterDetails.types';
import numeral from 'numeral';

const CoinUserTransactionDetails = () => {

  const [transactions, setTransactions] = useState<MembersClubBalanceHistory[]>([]);
  const [value, onChange] = useState<Value>([dayjs().subtract(30, 'day').toDate(), dayjs().toDate()]);

  const getCoinsTransactionDetails = useCallback(async () => {
    const params = qs.stringify({
      _where: {
        source: ['sent', 'coingift'],
        created_at_gte: dayjs(value[0]).format("YYYY-MM-DD"),
        created_at_lte: dayjs(value[1]).format("YYYY-MM-DD"),
      }

    })
    //source=sent&source=coingift&created_at=${dayjs(value[0]).format("YYYY-MM-DD")}&created_at=${dayjs(value[1]).format("YYYY-MM-DD")}
    const { data: userCoinTransactions } = await api.get(`/membersclub-balance-histories?${params}`)
    console.log('userCoinTransactions', userCoinTransactions)
    setTransactions(userCoinTransactions);
  }, [value]);


  useEffect(() => {
    getCoinsTransactionDetails()
  }, [getCoinsTransactionDetails])
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'center',
      paddingLeft: 20,
      paddingTop: 20,
    }} className=''>
      <h1 className='h1'>Coins Transactions</h1>
      <div className="row" style={{
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '30px',
        zIndex: 99999
      }}>
        <DateRangePicker
          maxDate={dayjs().toDate()}
          className={"daterange-picker"}
          onChange={(value: any) => {
            onChange(value as Range<ValuePiece>);
          }} value={value} />
        <button className="btn btn-primary" onClick={() => {
          getCoinsTransactionDetails()
        }}>Search</button>
      </div>
      <table className="table table-striped" >
        <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">User</th>
            <th scope="col">Email</th>
            <th scope="col">Source</th>
            <th scope="col">Amount</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => {
            return (
              <tr key={transaction.id}>
                <td>{dayjs(transaction.created_at).format("DD/MM/YYYY")}</td>
                <td>{transaction.fromUser?.firstName + ' ' + transaction.fromUser?.lastName}</td>
                <td>{transaction.fromUser?.email}</td>
                <td>{transaction.source}</td>
                <td>{numeral(transaction.change).format('0,0')}</td>

              </tr>
            )
          })}
        </tbody>
      </table>
      <div style={{
        minHeight: '300px',
      }} />
    </div>
  )
}

export default CoinUserTransactionDetails