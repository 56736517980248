import numeral from "numeral"
import { DashboardType } from "../Main";
import './DashboardCounter.css'
import { useHistory } from "react-router-dom";
import i18n from "../../../i18n";

numeral.register('locale', 'he', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'אלף',
    million: 'מליון',
    billion: 'בליון',
    trillion: 'טריליון'
  },
  ordinal: function (number) {
    return number === 1 ? 'ראשון' : 'שני';
  },
  currency: {
    symbol: '₪'
  }
});
numeral.locale('he');

const NUMBER_FORMAT = '0,0';
const NIS_ICON = '₪';
const MONEY_FORMAT = `$0,0`;

const DashboardCard = ({ title, value, onOpen, money = false }: { title: string, value: number; onOpen: () => void; money?: boolean }) => {
  return <div className="col-md-3 grid-margin stretch-card" onClick={onOpen}>
    <div className="card">
      <div className="card-body">
        <p className="card-title">{title}</p>
        <div className="d-flex justify-content-between align-items-baseline">
          <h2 className="text-success">{numeral(value).format(money ? MONEY_FORMAT : NUMBER_FORMAT)}</h2>
        </div>
      </div>
    </div>
  </div>
}

const DashboardCounters = ({ data }: { data: DashboardType }) => {
  const history = useHistory();

  return (
    <div className="row" style={{
      display: "flex",
      flexDirection: "column",
    }}>
      <h1 style={{
        fontSize: "2rem",
      }}>{i18n.t("coin-transactions")}</h1>
      <div className="row">

        <DashboardCard
          title={i18n.t("users-transaction")}
          value={data.total_user_coins_transactions}
          onOpen={() => {
            history.push('/coin-user-transactions')
          }}
        />
        <DashboardCard title={i18n.t("orders-purchased")} value={data.total_user_coins_order_payments}
          onOpen={() => {
            history.push('/coin-purchased-orders-details')
          }}
        />
        <DashboardCard title={i18n.t("users-cashback")} value={data.total_user_coins_cashback} money
          onOpen={() => {
            history.push('/coin-order-cashback-details')
          }}
        />
        <DashboardCard title={i18n.t("coin-purchases")} value={data.total_user_coins_purchases} money
          onOpen={() => {
            history.push('/coin-purchases-details')
          }}
        />
      </div>

      <h1 style={{
        fontSize: "2rem",
      }}>{i18n.t("general-transactions")}</h1>
      <div className="row">

        <DashboardCard title={i18n.t("total-donations")} value={data.total_user_contributions} money
          onOpen={() => {
            history.push('/contributions-details')
          }}
        />
        <DashboardCard title={i18n.t("average-order-value")} value={data.avg_order} money
          onOpen={() => {
            history.push('/average-orders-details')
          }}
        />
        <DashboardCard title={i18n.t("paid-orders-with-coins")}
          value={data.total_orders_coins}
          onOpen={() => {
            history.push('/orders-paid-with-coins-details?paidWithCoins=true');
          }}
        />
        <DashboardCard title={i18n.t("paid-orders-with-cash")} value={data.total_orders_money}
          onOpen={() => {
            history.push('/orders-paid-with-coins-details?paidWithCoins=false');
          }}
        />
      </div>
    </div>

  )
}

export default DashboardCounters