import { useCallback, useEffect, useState } from 'react'
import { api } from '../../../../utils/api';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import dayjs from 'dayjs';
import { Range, Value, ValuePiece } from '../../Main';
import qs from 'qs';
import { OrderContribution } from './CounterDetails.types';
import numeral from 'numeral';

const ContributionDetails = () => {

  const [transactions, setTransactions] = useState<OrderContribution[]>([]);
  const [value, onChange] = useState<Value>([dayjs().subtract(30, 'day').toDate(), dayjs().toDate()]);

  const getOrderContributions = useCallback(async () => {
    const params = qs.stringify({
      _where: {
        created_at_gte: dayjs(value[0]).format("YYYY-MM-DD"),
        created_at_lte: dayjs(value[1]).format("YYYY-MM-DD"),
      }

    })
    const { data: ordersContributions } = await api.get(`/order-contributions?${params}`)
    console.log('ordersContributions', ordersContributions)
    setTransactions(ordersContributions);
  }, [value]);


  useEffect(() => {
    getOrderContributions()
  }, [getOrderContributions])
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'center',
      paddingLeft: 20,
      paddingTop: 20,
    }} className=''>
      <h1 className='h1'>Contributions</h1>
      <div className="row" style={{
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '30px',
        zIndex: 99999
      }}>
        <DateRangePicker
          maxDate={dayjs().toDate()}
          className={"daterange-picker"}
          onChange={(value: any) => {
            onChange(value as Range<ValuePiece>);
          }} value={value} />
        <button className="btn btn-primary" onClick={() => {
          getOrderContributions()
        }}>Search</button>
      </div>
      <table className="table table-striped" >
        <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Icon</th>
            <th scope="col">Contribution</th>
            <th scope="col">Amount</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => {
            const orderTotal = transaction.order?.total ? transaction.order.total : 0;
            return (
              <tr key={transaction.id}>
                <td>{dayjs(transaction.created_at).format("DD/MM/YYYY")}</td>
                <td><img src={api.getMedia!(transaction.contribution?.image)} alt='contribution-logo' /></td>
                <td>{transaction.contribution?.name}</td>
                <td>{numeral(orderTotal * 0.01).format('$0,0.00')}</td>

              </tr>
            )
          })}
        </tbody>
      </table>
      <div style={{
        minHeight: '300px',
      }} />
    </div>
  )
}

export default ContributionDetails