
// @ts-nocheck
import i18n from 'i18n-js';
import en from './en.json'
import he from './he.json'

declare namespace I18n {
    function getLang() : string
}
// Set the key-value pairs for the different languages you want to support.
i18n.translations = {
    en,
    "en-MX": en,
    "en-US": en,
    "he-IL": he,
    he
};

const defaultLocale = "he"
i18n.defaultLocale = defaultLocale;
i18n.locale = "he"

// i18n.locale = navigator.language || navigator.userLanguage//defaultLocale//Localization.locale;
i18n.locale = 'he';
// Set the locale once at the beginning of your app.
// i18n.locale = defaultLocale//Localization.locale;

i18n.isRTL = () => {
    return i18n.locale.indexOf("he") >= 0
}

i18n.getLang = () => {
    return i18n.locale.substring(0,2)
}
export default i18n