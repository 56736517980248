import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { StrapiImage } from '../utils/api';


export type FoodMenuCategory = {
  MenualID: string;
  created_at: Date;
  description: string;
  id: number;
  name: string;
  order: number;
  photo?: StrapiImage;
  published_at: Date;
  restaurant: number | RestaurantType
  updated_at: Date;
}

export type CartFoodType = FoodType & {
  qty: number;
}

export type FoodType = {
  Active: boolean;
  Dairy: boolean;
  AddID: string;
  spicy: boolean;
  popular: boolean;
  ExtrasCount: string;
  OptionalsCount: string;
  Delivery: boolean;
  MenualID: string;
  NewProduct: boolean;
  Order: number;
  Takeout: boolean;
  Vegetarian: boolean;
  created_at: string;
  description: string;
  hasNotes: boolean;
  description_he: string;
  id: number;
  imageName?: string;
  imageUrl?: string;
  menu: null,
  Customized: CustomizedType,
  menu_category: FoodMenuCategory,
  name: string;
  name_he: string;
  photo?: StrapiImage,
  preparationTime: null,
  price: number;
  published_at: Date;
  restaurant: RestaurantType;
  soldcount?: number;
  updated_at: Date;
}

export type ExtraType = {
  priceChange: string;
  name_he: string;
  name: string;
  id: number;
}

export type OptionType = {
  priceChange: string;
  menu_optional: {
    name_he: string;
    name: string;
  };
  name_he: string;
  name: string;
}

export type MultiOptionType = {
  priceChange: string;
  name_he: string;
  name: string;
  qty: number;
}

export type CustomizedType = {
  Options: OptionType[],
  Extras: ExtraType[],
  MultiOptions: MultiOptionType[],
  Notes: string;
}

export type LocationType = {

  city: string;
  created_at: Date;
  id: number;
  latitude: number;
  longitude: number;
  published_at: Date;
  state: string;
  updated_at: Date;

}

export type TagsType = {
  created_at: Date;
  id: number;
  published_at: Date;
  restaurant?: number;
  updated_at: Date;
  value: string;
}

export type RestaurantType = {
  minimum_order_value?: number;
  LocationID?: string;
  address?: string;
  phrase?: string;
  averagePrice?: number;
  averageTime?: number;
  phone?: string;
  latitude?: number;
  longitude?: number;
  orderCoinsPercentage?: string;
  membersClubMessage?: string;
  restaurantMessageTitle?: string;
  restaurantMessageBody?: string;
  recommendedItems?: FoodType[];
  message?: string;
  color?: string;
  colorText?: string;
  created_at?: Date;
  currency?: string;
  description?: string | undefined;
  facebook?: string | undefined;
  joinCoins?: number;
  joinCoinsText_en?: string;
  joinCoinsText_he?: string;
  isClosed?: boolean;
  operation_time?: any;
  featured?: StrapiImage[],
  cover?: StrapiImage,
  id?: number;
  instagram?: string,
  kosher?: boolean;
  lang?: string;
  location?: LocationType,
  logo?: StrapiImage,
  name?: string;
  photos?: StrapiImage[],
  published_at?: Date;
  rate?: number;
  restaurant_types?: RestaurantTypesType[],
  tags?: TagsType[];
  telephone?: string;
  updated_at?: Date;
}

export declare type RestaurantTypesType = {
  "created_at": Date;
  "id": number;
  "location": LocationType,
  "name": string;
  "published_at": Date;
  "updated_at": Date;
}

let initialState: RestaurantType = {};

const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    selectRestaurant: (state, action: PayloadAction<RestaurantType>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      // console.log("selectRestaurant", action.payload)
      state = action.payload
      return state
    }
  }

})

export const {
  selectRestaurant
} = restaurantSlice.actions

export default restaurantSlice