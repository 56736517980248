import { useCallback, useEffect, useState } from 'react'
import i18n from '../../i18n';
import { api } from '../../utils/api';
import numeral from 'numeral'
import DistributionChart from './DistributionChart';
import SalesChart from './SalesChart';
import { validateNumber } from '../../utils/numbers';
import { usePrevious } from '../../hooks/usePrevious';
import { loadScript } from '../../utils/scripts';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import './Main.css'
import dayjs from 'dayjs';
import DashboardCounters from './DashboardCounter/DashboardCounters';

export type Range<T> = [T, T];
export type ValuePiece = Date | null;
export type Value = Range<ValuePiece>;



export type DashboardType = {
    "res_yesterday": number;
    "res_today": number;
    "count_orders_yesterday": number;
    "count_orders_today": number;
    "orders_today": number;
    "orders_9_today": number;
    "orders_4_today": number;
    "orders_5_today": number;
    "bolepo_yesterday_coins": number | undefined;
    "bolepo_coins": number;
    "business_yesterday_coins": number;
    "business_coins": number;
    "user_entrance_yesterday": number;
    "user_entrance_today": number;
    "users_yesterday": number;
    "users_today": number;
    total_user_coins_transactions: number;
    total_user_coins_order_payments: number;
    total_user_coins_cashback: number;
    total_user_coins_purchases: number;
    total_user_contributions: number;
    avg_order: number;
    total_orders_coins: number;
    total_orders_money: number;
}


const Main = () => {
    const [Dashboard, setDashboard] = useState<DashboardType | null>(null)
    const [DOMLoaded, setDOMLoaded] = useState(false)

    const [value, onChange] = useState<Value>([dayjs().subtract(30, 'day').toDate(), dayjs().toDate()]);

    let restaurantChange = 0
    let ordersChange = 0
    let userEntranceChange = 0
    let userTotal = 0

    if (Dashboard) {
        restaurantChange = validateNumber((Dashboard.res_today - (Dashboard.res_yesterday)) / Dashboard.res_today * 100)
        ordersChange = validateNumber((Dashboard.count_orders_today - (Dashboard.count_orders_yesterday)) / Dashboard.count_orders_today * 100)
        if (ordersChange)
            ordersChange = Number(ordersChange.toFixed(2))
        if (restaurantChange)
            restaurantChange = Number(restaurantChange.toFixed(2))

        userEntranceChange = validateNumber((Dashboard.user_entrance_today / (Dashboard.user_entrance_yesterday)))
        userTotal = validateNumber((Dashboard.users_today / (Dashboard.users_yesterday)))

    }

    const buildGraphs = useCallback(() => {

        //@ts-ignore
        if ($('#offlineProgress').length) {
            //@ts-ignore
            const bar = new ProgressBar.Circle(offlineProgress, {
                color: '#000',
                // This has to be the same size as the maximum width to
                // prevent clipping
                strokeWidth: 6,
                trailWidth: 6,
                easing: 'easeInOut',
                duration: 1400,
                text: {
                    autoStyleContainer: true,
                    style: {
                        color: "#fff",
                        position: 'absolute',
                        left: '40%',
                        top: '50%'
                    }
                },
                svgStyle: {
                    width: '90%'
                },
                from: {
                    color: '#f1536e',
                    width: 6
                },
                to: {
                    color: '#f1536e',
                    width: 6
                },
                // Set default step function for all animate calls
                step: function (state: any, circle: any) {
                    circle.path.setAttribute('stroke', state.color);
                    circle.path.setAttribute('stroke-width', state.width);

                    const value = Math.round(circle.value() * 100);
                    if (value === 0) {
                        circle.setText('');
                    } else {
                        circle.setText(value);
                    }

                }
            });

            bar.text.style.fontSize = '1rem';
            console.log("usersChange", userEntranceChange)
            bar.animate(userEntranceChange); // Number from 0.0 to 1.0
        }
        //@ts-ignore
        if ($('#onlineProgress').length) {
            //@ts-ignore
            const bar = new ProgressBar.Circle(onlineProgress, {
                color: '#000',
                // This has to be the same size as the maximum width to
                // prevent clipping
                strokeWidth: 6,
                trailWidth: 6,
                easing: 'easeInOut',
                duration: 1400,
                text: {
                    autoStyleContainer: true,
                    style: {
                        color: "#fff",
                        position: 'absolute',
                        left: '40%',
                        top: '50%'
                    }
                },
                svgStyle: {
                    width: '90%'
                },
                from: {
                    color: '#fda006',
                    width: 6
                },
                to: {
                    color: '#fda006',
                    width: 6
                },
                // Set default step function for all animate calls
                step: function (state: any, circle: any) {
                    circle.path.setAttribute('stroke', state.color);
                    circle.path.setAttribute('stroke-width', state.width);

                    const value = Math.round(circle.value() * 100);
                    if (value === 0) {
                        circle.setText('');
                    } else {
                        circle.setText(value);
                    }

                }
            });

            bar.text.style.fontSize = '1rem';
            console.log("userTotal", userTotal)
            bar.animate(userTotal); // Number from 0.0 to 1.0
        }
    }, [userEntranceChange, userTotal])

    useEffect(() => {
        setDOMLoaded(true)

    }, [])

    const prevDOMLoaded = usePrevious(Dashboard)

    const getDashboard = useCallback(() => {
        api.get("/restaurants/dashboard?startDate=" + dayjs(value[0]).format("YYYY-MM-DD") + "&endDate=" + dayjs(value[1]).format("YYYY-MM-DD") + "&timezone=Asia/Jerusalem")
            .then(({ data }) => {
                console.log("dashboard", data)
                setDashboard(data)
            })
            .catch((e) => {
                console.warn("could not get dashboard", e)
            })
    }, [value])



    useEffect(() => {
        if (!prevDOMLoaded && DOMLoaded && Dashboard) {

            loadScript('/js/dashboard.js', 'dashboard-js');
            buildGraphs();

        }
    }, [prevDOMLoaded, DOMLoaded, Dashboard, buildGraphs])

    useEffect(() => {
        getDashboard()
    }, [getDashboard])

    const getTextColor = (value: number) => {
        return value > 0 ? "text-success" : "text-danger"
    }

    const getArrowDirection = (value: number) => {
        return value > 0 ? " mdi-arrow-up" : " mdi-arrow-down"
    }

    return (



        <div className="container-fluid page-body-wrapper">
            <div className="main-panel">
                {Dashboard ? <div className="content-wrapper">
                    <div className="row" style={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '30px'
                    }}>
                        <DateRangePicker
                            maxDate={dayjs().toDate()}
                            className={"daterange-picker"}
                            onChange={(value: any) => {
                                onChange(value as Range<ValuePiece>);
                            }} value={value} />
                    </div>
                    <DashboardCounters data={Dashboard} />
                    <div className="row">
                        <div className="col-xl-6 grid-margin">
                            <div className="row">
                                <div className="col-md-6 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <p className="card-title">{i18n.t("businesses")}</p>
                                            <div className="d-flex flex-wrap align-items-baseline">
                                                <h2 className="mr-3">{Dashboard.res_today}</h2>
                                                <i className={"mdi  mr-1 " + getTextColor(restaurantChange) + getArrowDirection(restaurantChange)}></i>
                                                <span>
                                                    <p className={"mb-0 font-weight-medium " + getTextColor(restaurantChange)}>
                                                        {restaurantChange}%
                                                    </p></span>
                                            </div>
                                            <p className="mb-0 text-muted">{i18n.t("business-description")}</p>
                                        </div>
                                        <canvas id="users-chart"></canvas>
                                    </div>
                                </div>
                                <div className="col-md-6 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <p className="card-title">{i18n.t("orders")}</p>
                                            <div className="d-flex flex-wrap align-items-baseline">
                                                <h2 className="mr-3">{Dashboard.count_orders_today}</h2>
                                                <i className={`mdi ${getArrowDirection(ordersChange)} mr-1 ${getTextColor(ordersChange)}`}></i><span><p className={`mb-0 ₪{getTextColor(ordersChange)} font-weight-medium`}>{ordersChange}%</p></span>
                                            </div>
                                            <p className="mb-0 text-muted">{i18n.t("order-growth")}</p>
                                        </div>
                                        <canvas id="projects-chart"></canvas>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 stretch-card">
                                    {/* <div className="card">
                                        <div className="card-body">
                                            <p className="card-title">{i18n.t("user-activity")}</p>
                                            <div className="row mt-4">
                                                <div className="col-md-6 stretch-card">
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-6">
                                                            <div id="offlineProgress"></div>
                                                        </div>
                                                        <div className="col-6 pl-0">
                                                            <p className="mb-0">{i18n.t("user-entrance")}</p>
                                                            <h2>{numeral(Dashboard.user_entrance_today).format("0,0")}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 stretch-card mt-4 mt-md-0">
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-6">
                                                            <div id="onlineProgress"></div>
                                                        </div>
                                                        <div className="col-6 pl-0">
                                                            <p className="mb-0">{i18n.t("total-users")}</p>
                                                            <h2>{Dashboard.users_today}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <p className="card-title">{i18n.t("user-activity")}</p>
                                    <div className="row mt-4">
                                        <div className="col-md-6 stretch-card">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-6">
                                                    <div id="offlineProgress"></div>
                                                </div>
                                                <div className="col-6 pl-0">
                                                    <p className="mb-0">{i18n.t("user-entrance")}</p>
                                                    <h2>{numeral(Dashboard.user_entrance_today).format("0,0")}</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 stretch-card mt-4 mt-md-0">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-6">
                                                    <div id="onlineProgress"></div>
                                                </div>
                                                <div className="col-6 pl-0">
                                                    <p className="mb-0">{i18n.t("total-users")}</p>
                                                    <h2>{Dashboard.users_today}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="card">
                                <div className="card-body">
                                    <p className="card-title">{i18n.t("coins")}</p>
                                    <div className="d-flex flex-wrap mb-4 mt-4 pb-4">
                                        <div className="mr-4 mr-md-5">
                                            <p className="mb-0">{i18n.t("bolepo-coins")}</p>
                                            <h4>{numeral(Dashboard.bolepo_yesterday_coins).format("0,0")}</h4>
                                        </div>

                                        <div className="mr-4 mr-md-5">
                                            <p className="mb-0">{i18n.t("business-coins")}</p>
                                            <h4>{numeral(Dashboard.business_coins).format("0,0")}</h4>
                                        </div>

                                    </div>
                                    <canvas id="total-sales-chart"></canvas>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3 grid-margin stretch-card">
                            <div className="card border-0 border-radius-2 bg-success">
                                <div className="card-body">
                                    <div className="d-flex flex-md-column flex-xl-row flex-wrap  align-items-center justify-content-between">
                                        <div className="icon-rounded-inverse-success icon-rounded-lg">
                                            <i className="mdi mdi-arrow-top-right"></i>
                                        </div>
                                        <div className="text-white">
                                            <p className="font-weight-medium mt-md-2 mt-xl-0 text-md-center text-xl-left">{'מכירות'}</p>
                                            <div className="d-flex flex-md-column flex-xl-row flex-wrap align-items-baseline align-items-md-center align-items-xl-baseline">
                                                <h3 className="mb-0 mb-md-1 mb-lg-0 mr-1">₪{numeral(Dashboard.orders_today).format("0,0")}</h3>

                                            </div>
                                            <small className="mb-0">{i18n.t("this-month")}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 grid-margin stretch-card">
                            <div className="card border-0 border-radius-2 bg-info">
                                <div className="card-body">
                                    <div className="d-flex flex-md-column flex-xl-row flex-wrap  align-items-center justify-content-between">
                                        <div className="icon-rounded-inverse-info icon-rounded-lg">
                                            <i className="mdi mdi-basket"></i>
                                        </div>
                                        <div className="text-white">
                                            <p className="font-weight-medium mt-md-2 mt-xl-0 text-md-center text-xl-left">{i18n.t("bolepo-comission")}</p>
                                            <div className="d-flex flex-md-column flex-xl-row flex-wrap align-items-baseline align-items-md-center align-items-xl-baseline">
                                                <h3 className="mb-0 mb-md-1 mb-lg-0 mr-1">₪{numeral(Dashboard.orders_9_today).format("0,0")}</h3>

                                            </div>
                                            <small className="mb-0">{i18n.t("this-month")}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 grid-margin stretch-card">
                            <div className="card border-0 border-radius-2 bg-danger">
                                <div className="card-body">
                                    <div className="d-flex flex-md-column flex-xl-row flex-wrap  align-items-center justify-content-between">
                                        <div className="icon-rounded-inverse-danger icon-rounded-lg">
                                            <i className="mdi mdi-chart-donut-variant"></i>
                                        </div>
                                        <div className="text-white">
                                            <p className="font-weight-medium mt-md-2 mt-xl-0 text-md-center text-xl-left">{i18n.t("back-community")}</p>
                                            <div className="d-flex flex-md-column flex-xl-row flex-wrap align-items-baseline align-items-md-center align-items-xl-baseline">
                                                <h3 className="mb-0 mb-md-1 mb-lg-0 mr-1">₪{numeral(Dashboard.orders_4_today).format("0,0")}</h3>

                                            </div>
                                            <small className="mb-0">{i18n.t("this-month")}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 grid-margin stretch-card">
                            <div className="card border-0 border-radius-2 bg-warning">
                                <div className="card-body">
                                    <div className="d-flex flex-md-column flex-xl-row flex-wrap  align-items-center justify-content-between">
                                        <div className="icon-rounded-inverse-warning icon-rounded-lg">
                                            <i className="mdi mdi-chart-multiline"></i>
                                        </div>
                                        <div className="text-white">
                                            <p className="font-weight-medium mt-md-2 mt-xl-0 text-md-center text-xl-left">{i18n.t("operation")}</p>
                                            <div className="d-flex flex-md-column flex-xl-row flex-wrap align-items-baseline align-items-md-center align-items-xl-baseline">
                                                <h3 className="mb-0 mb-md-1 mb-lg-0 mr-1">₪{numeral(Dashboard.orders_5_today).format("0,0")}</h3>

                                            </div>
                                            <small className="mb-0">{i18n.t("this-month")}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <DistributionChart />
                        <SalesChart />
                    </div>
                </div> : null}
                <footer className="footer">
                    <div className="w-100 clearfix">
                        <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © 2021 Mabali. All rights reserved.</span>
                        <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Powered by <i className="mdi mdi-heart-outline text-danger"> <a href="http://www.menual.net" target="_blank">MENUAL</a></i></span>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Main


