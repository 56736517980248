//@ts-nocheck
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import i18n from '../../i18n'
import { api } from '../../utils/api'

const SalesChart = () => {

    const [Sales, setSales] = useState([])

    const getData = () => {
        api.get("/restaurants/sales?start=" + dayjs().format("YYYY-MM-DD"))
            .then(({ data }) => {
                if (data.length){
                    let report = data.pop();

                    if(report){
                        setSales([report.day1, report.day2, report.day3, report.day4, report.day5, report.day6, report.day7])
                    }
                }

                    
            })
            .catch((e) => {
                console.warn("could not get sales", e)
            })
    }

    useEffect(() => {

        if(Sales.length == 0){
            return;
        }

        console.log("Sales Report", Sales)
        if ($("#sale-report-chart").length) {
            var CurrentChartCanvas = $("#sale-report-chart").get(0).getContext("2d");
            var CurrentChart = new Chart(CurrentChartCanvas, {
              type: 'bar',
              data: {
                labels: [i18n.t("sunday"), i18n.t("monday"),i18n.t("tuesday"), i18n.t("wednesday"), i18n.t("thursday"), i18n.t("friday"), i18n.t("saturday")],
                datasets: [{
                    label: i18n.t("sales-lbl"),
                    data: Sales,
                    backgroundColor: ["#3da5f4","#e0f2ff","#3da5f4","#e0f2ff","#3da5f4","#e0f2ff","#3da5f4","#e0f2ff","#3da5f4","#e0f2ff","#3da5f4"]
                  }
                ]
              },
              options: {
                responsive: true,
                maintainAspectRatio: true,
                layout: {
                  padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                  }
                },
                scales: {
                  yAxes: [{
                    display: true,
                    gridLines: {
                      drawBorder: false
                    },
                    ticks: {
                      fontColor: "#000",
                      display: true,
                      padding: 20,
                      fontSize: 14,
                      stepSize: 10000,
                      callback: function(value) {
                        var ranges = [
                            { divider: 1e6, suffix: 'M' },
                            { divider: 1e3, suffix: 'k' }
                        ];
                        function formatNumber(n) {
                            for (var i = 0; i < ranges.length; i++) {
                              if (n >= ranges[i].divider) {
                                  return (n / ranges[i].divider).toString() + ranges[i].suffix;
                              }
                            }
                            return n;
                        }
                        return "₪" + formatNumber(value);
                      }
                    }
                  }],
                  xAxes: [{
                    stacked: false,
                    categoryPercentage: .6,
                    ticks: {
                      beginAtZero: true,
                      fontColor: "#000",
                      display: true,
                      padding: 20,
                      fontSize: 14
                    },
                    gridLines: {
                      color: "rgba(0, 0, 0, 0)",
                      display: true
                    },
                    barPercentage: .7
                  }]
                },
                legend: {
                  display: false
                },
                elements: {
                  point: {
                    radius: 0
                  }
                }
              }
            });
          }
    }, [Sales])

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className="col-xl-9 grid-margin stretch-card">
            <div className="card">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body h-100 d-flex flex-column">
                            <p className="card-title">{i18n.t("sales-report")}</p>


                            <canvas id="sale-report-chart"></canvas>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default SalesChart
